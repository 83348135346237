import React, { useState } from "react";
import classes from "./Login.module.css";
import { required, validEmail } from "../../common/Validadores/Validadores";
import {
  Button,
  TextField,
  Grid,
  IconButton,
  Link,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import {
  AccountCircle,
  LockOpen,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import ResetearContrasena from "./ResetearContrasena/ResetearContrasena";
import CarouselPersonalizado from "../../common/carousel/carousel";
import { banner1, banner2, banner3, banner4 } from "../../../imagenes/Banners";
import { Ejecutar } from "../../common/server/funcionesServidor";
import OrdenServicioTecnicos from "../Content/OrdenServicio/Orden/OrdenServicioTecnico";
import { IOrdenServicioTecnicoLogin } from "../../../AppInterfaces";
import { Box } from "@mui/material";

export default function LoginTecnico({ rutaIcono }: { rutaIcono: string }) {
  const [data, setData] = useState({ email: "", password: "" });
  const [loginState, setLoginState] = useState<IOrdenServicioTecnicoLogin>();
  const [errores, setErrores] = useState({ email: "", password: "" });
  const [mostrarPass, setMostrarPass] = useState(false);
  const [resetearContrasena, setResetearContrasena] = useState(false);
  const items = [
    {
      altText: "",
      caption: "",
      rutaImagen: banner1,
      id: "1",
    },
    {
      altText: "",
      caption: "",
      rutaImagen: banner2,
      id: "2",
    },
    {
      altText: "",
      caption: "",
      rutaImagen: banner3,
      id: "3",
    },
    {
      altText: "",
      caption: "",
      rutaImagen: banner4,
      id: "4",
    },
  ];

  const onLogIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const respuesta: IOrdenServicioTecnicoLogin[] = await Ejecutar(
      "api/OrdenServicioTecnicos/login",
      undefined,
      { email: email, clave: password },
      undefined
    );

    if (respuesta.length) {
      setLoginState(respuesta[0]);
    }
  };

  /**envia solicitud para inicio de sesión*/
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onLogIn({ email: data.email, password: data.password });
  };

  /**valida y asigna los valores del formulario*/
  const handleInputChange = ({
    target: { name, type, value, checked },
  }: {
    target: { name: string; type: string; value: string; checked?: boolean };
  }) => {
    const nvalue =
      type === "checkbox"
        ? (checked ?? false)
        : type === "number" &&
            (value.length === 0 || value.length === 1 || +value !== 0)
          ? +(value || 0)
          : value;

    if (typeof nvalue === "number" && nvalue < 0) return;

    setData({ ...data, [name]: nvalue });

    let mensaje = "";
    setErrores({ email: "", password: "" });
    if (name === "email" && typeof nvalue === "string") {
      if (!validEmail(nvalue)) {
        mensaje = "Email no valido";
      }
      if (!required(nvalue)) {
        mensaje = "Email requerido";
      }
      setErrores({ ...errores, [name]: mensaje });
    }

    if (name === "password") {
      if (!required(nvalue)) {
        mensaje = "Contraseña requerida";
      }
      setErrores({ ...errores, [name]: mensaje });
    }
  };

  /**muestra o oculta la página para resetear contraseña*/
  const toggleResetearContrasena = () =>
    setResetearContrasena((resetearContrasena) => !resetearContrasena);

  return (
    <>
      {loginState ? (
        <OrdenServicioTecnicos loginState={loginState} />
      ) : (
        <div className={classes.body}>
          <Grid container>
            <Grid item xs={12} md={6} lg={4}>
              <div className={classes.container}>
                {resetearContrasena ? (
                  <ResetearContrasena
                    toggleResetearContrasena={toggleResetearContrasena}
                  />
                ) : (
                  <>
                    <div className={classes.titulo}>
                      <img
                        className={classes.imgCompania}
                        src={rutaIcono}
                        alt="Imagen Compañía"
                      />
                      <Typography component="h1" variant="h3">
                        Bienvenido al portal de ordenes de servicio Continuo
                      </Typography>
                      <Typography component="h2" variant="h5">
                        Iniciar Sesión
                      </Typography>
                    </div>

                    <Paper
                      className={["colorFondo", classes.contenedorInterno].join(
                        " "
                      )}
                    >
                      <form onSubmit={handleSubmit}>
                        <Grid container item spacing={1} direction="column">
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid item>
                              <AccountCircle />
                            </Grid>
                            <Grid item xs={10}>
                              <TextField
                                className="inputs"
                                autoFocus
                                fullWidth
                                error={errores.email ? true : false}
                                helperText={errores.email}
                                name="email"
                                label="Email"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                onChange={handleInputChange}
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid item>
                              <LockOpen />
                            </Grid>
                            <Grid item xs={10}>
                              <TextField
                                className="inputs"
                                fullWidth
                                error={errores.password ? true : false}
                                helperText={errores.password}
                                name="password"
                                label="Contraseña"
                                type={mostrarPass ? "text" : "password"}
                                autoComplete="current-password"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                onChange={handleInputChange}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        edge="end"
                                        onClick={() =>
                                          setMostrarPass(
                                            (mostrarPass) => !mostrarPass
                                          )
                                        }
                                      >
                                        {mostrarPass ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid container item justifyContent="center">
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              className={["boton", classes.submitbutton].join(
                                " "
                              )}
                            >
                              Acceder
                            </Button>
                          </Grid>
                        </Grid>
                      </form>

                      <Link
                        className="enlace"
                        component="button"
                        variant="body2"
                        onClick={toggleResetearContrasena}
                      >
                        Se me olvidó la contraseña
                      </Link>
                    </Paper>
                  </>
                )}
              </div>
            </Grid>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Grid item xs={12} md={6} lg={8} className={classes.banner}>
                <CarouselPersonalizado items={items} />
              </Grid>
            </Box>
          </Grid>

          <img
            src="https://www.positivessl.com/images/seals/positivessl_trust_seal_sm_124x32.png"
            alt="TRUSTLOGO"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              cursor: "pointer",
            }}
            onClick={() => window.open("https://secure.trust-provider.com/")}
          />
        </div>
      )}
    </>
  );
}
