import React from "react";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

/**
 * presenta en carouse los objetos
 * @param items objeto de elementos [{id,rutaImagen,alText,caption}]
 * @param mostrarControles booleano que indica si se van a presentar los controles
 */
export default function CarouselPersonalizado({
  items,
}: {
  items: {
    altText: string;
    caption: string;
    rutaImagen: string;
    id: string;
  }[];
}) {
  return (
    <div>
      <Carousel
        autoPlay
        infiniteLoop
        interval={5000}
        transitionTime={500}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        renderArrowPrev={() => null}
        renderArrowNext={() => null}
      >
        {items.map((item) => (
          <div key={item.altText}>
            {item.rutaImagen ? (
              <img
                src={item.rutaImagen}
                alt={item.altText}
                style={{ height: "100vh" }}
              />
            ) : null}
          </div>
        ))}
      </Carousel>
    </div>
  );
}
