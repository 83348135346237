import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEditing: false,
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    startEditing: (state) => {
      state.isEditing = true;
    },
    stopEditing: (state) => {
      state.isEditing = false;
    },
  },
});

export const { startEditing, stopEditing } = documentSlice.actions;

export default documentSlice.reducer;
