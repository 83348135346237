import { IRNC } from "../../../AppInterfaces";
import { Consultar } from "../server/funcionesServidor";
import { frecuenciaDias } from "../../Content/Financiamiento/Prestamo/Editor/Editor";
import { Column } from "@material-table/core";

/**Agrega los dias indicados a la fecha*/
export const agregarDias = (Fecha: Date | string, dias: number): Date => {
  let result = new Date();

  if (typeof Fecha === "string") {
    const fechaSplit = Fecha.split("T")[0].split("-");
    result = new Date(+fechaSplit[0], +fechaSplit[1] - 1, +fechaSplit[2]);
  } else result = Fecha;

  result.setDate(result.getDate() + dias);
  return result;
};

export const fechaDiferencia = (
  fecha1: Date | string = new Date(),
  fecha2: Date | string = new Date(),
  factor: "DIA" | "HORA" | "MINUTO" | "SEGUNDO" = "DIA"
): number => {
  let fecha1F;
  let fecha2F;

  if (typeof fecha1 === "string") {
    const fechaSplit = fecha1.split("T")[0].split("-");
    fecha1F = new Date(+fechaSplit[0], +fechaSplit[1] - 1, +fechaSplit[2]);
  } else fecha1F = fecha1;

  if (typeof fecha2 === "string") {
    const fechaSplit = fecha2.split("T")[0].split("-");
    fecha2F = new Date(+fechaSplit[0], +fechaSplit[1] - 1, +fechaSplit[2]);
  } else fecha2F = fecha2;

  const factF =
    1000 *
    (factor === "DIA"
      ? 60 * 60 * 24
      : factor === "HORA"
        ? 60 * 60
        : factor === "MINUTO"
          ? 60
          : 1);

  return Math.floor((fecha1F.getTime() - fecha2F.getTime()) / factF);
};

/**Le asigna nombre a cada componente de la tabla */
export const AsignarNombreMaterialTable = (): void => {
  const tabla = document.getElementById("materialTable");

  if (tabla) {
    const cabezeraTabla = tabla.getElementsByClassName(
      "MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters"
    )[0];

    if (cabezeraTabla) {
      const busqueda = cabezeraTabla.getElementsByClassName(
        "MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart MuiInputBase-adornedEnd"
      )[0];
      if (busqueda) busqueda.id = "tablaBusqueda";

      const columnas = cabezeraTabla.getElementsByClassName(
        "MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit"
      )[0];
      if (columnas) columnas.id = "tablaColumnas";

      const csv = cabezeraTabla.getElementsByClassName(
        "MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit"
      )[1];
      if (csv) csv.id = "tablaCVS";
    }

    const grupos = tabla.getElementsByClassName(
      "MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters"
    )[1];
    if (grupos) grupos.id = "tablaGrupos";

    const cabeza = tabla.getElementsByClassName("MuiTableHead-root")[0];
    if (cabeza) cabeza.id = "tablaCabeza";
    if (cabeza) {
      const botonSeleccion = cabeza.getElementsByClassName(
        "MuiIconButton-label"
      )[0];
      if (botonSeleccion) botonSeleccion.id = "tablaBotonSeleccion";
    }

    const cuerpo = tabla.getElementsByClassName("MuiTableBody-root")[0];
    if (cuerpo) {
      cuerpo.id = "tablaCuerpo";

      const filtros = cuerpo.getElementsByClassName("MuiTableRow-root")[0];
      if (filtros) filtros.id = "tablaFiltros";

      const data = cuerpo.getElementsByClassName("MuiTableRow-root")[1];
      if (data) data.id = "tablaData";

      const boton1 = cuerpo.getElementsByClassName(
        "MuiButtonBase-root MuiIconButton-root"
      )[0];
      if (boton1) boton1.id = "tablaBoton1";

      const boton2 = cuerpo.getElementsByClassName(
        "MuiButtonBase-root MuiIconButton-root"
      )[1];
      if (boton2) boton2.id = "tablaBoton2";

      const boton3 = cuerpo.getElementsByClassName(
        "MuiButtonBase-root MuiIconButton-root"
      )[2];
      if (boton3) boton3.id = "tablaBoton3";

      const boton4 = cuerpo.getElementsByClassName(
        "MuiButtonBase-root MuiIconButton-root"
      )[3];
      if (boton4) boton4.id = "tablaBoton4";

      const boton5 = cuerpo.getElementsByClassName(
        "MuiButtonBase-root MuiIconButton-root"
      )[4];
      if (boton5) boton5.id = "tablaBoton5";

      const boton6 = cuerpo.getElementsByClassName(
        "MuiButtonBase-root MuiIconButton-root"
      )[5];
      if (boton6) boton6.id = "tablaBoton6";

      const boton7 = cuerpo.getElementsByClassName(
        "MuiButtonBase-root MuiIconButton-root"
      )[6];
      if (boton7) boton7.id = "tablaBoton7";
    }
  }
};

/**Convierte una imagen en base64 */
export const ConvertirImagenBase64 = (
  archivo: any,
  cb: (res: unknown) => void
): void => {
  const reader = new FileReader();
  reader.readAsDataURL(archivo);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.error("Error: ", error);
  };
};

/**Cambia el tamaño de una imagen */
export const CambiarTamanoImagen = (
  archivo: any,
  maxWidth: number,
  maxHeight: number,
  calidad: number
): void => {
  const reader = new FileReader();
  reader.readAsDataURL(archivo);
  reader.onload = function (event: any) {
    const dataUrl: any = event.target.result;

    const image = new Image();
    image.src = dataUrl;
    image.onload = function () {
      const canvas = document.createElement("canvas");

      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx: any = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, width, height);
      // cb(canvas.toDataURL("image/jpeg", calidad));
      return canvas.toDataURL("image/jpeg", calidad);
    };
  };
};

export async function ExportarPDF(
  columns: Column<any>[],
  data: Record<string, unknown>[] = [],
  filename = "data",
  titulo = ""
) {
  try {
    const JSpdf = typeof window !== "undefined" ? await import("jspdf") : null;
    const jsPdf = JSpdf?.jsPDF;

    if (!jsPdf) {
      throw new Error("jsPDF no disponible");
    }

    // Importar plugin autoTable
    const autoTable =
      typeof window !== "undefined" ? await import("jspdf-autotable") : null;

    if (!autoTable) {
      throw new Error("El plugin 'jspdf-autotable' no está disponible");
    }

    // Estilo por columna (alineación derecha para tipo currency)
    const columnStyles: Record<number, { halign: string }> = {};
    columns.forEach((columna, index) => {
      if (columna.type === "currency") {
        columnStyles[index] = { halign: "right" };
      }
    });

    // Formatear datos
    const finalData = data.map((row) => {
      const newRow = {} as Record<string, any>;

      columns.forEach((column) => {
        const valor = row[column.field as string] as number;

        newRow[column.field as string] =
          column.type === "currency" && !isNaN(valor)
            ? FormatearNumeroCurrency.format(valor)
            : valor;
      });

      return newRow;
    });

    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    // Configuración y generación del PDF
    const doc = new jsPdf(orientation, unit, size);
    doc.setFontSize(15);
    doc.text(titulo, 40, 40);

    // Configurar tabla usando autoTable
    //@ts-expect-error Propiedad existe
    doc.autoTable({
      startY: 80,
      head: [columns.map((col) => col.title)], // Títulos de columnas
      body: finalData, // Datos procesados
      columnStyles, // Estilo de columnas
    });

    // Guardar el archivo
    doc.save(`${filename}.pdf`);
  } catch (err) {
    console.error(
      `Error al exportar PDF: No se pudo generar el archivo. Detalles: ${(err as Record<string, unknown>).message}`
    );
  }
}

export const formatNumberDecimals = (number: number, decimals = 2) => {
  return +number.toFixed(decimals);
};

/**Formatea el monto a moneda */
export const FormatearNumero = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
/**Formatea el monto a moneda con etiqueta DOP */
export const FormatearNumeroCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "narrowSymbol",
});

/**Convierte un texto en un objeto fecha: Formato: YYYY-MM-DD*/
export const convertirTextoAFecha = (fecha: string): Date => {
  if (fecha) {
    const datosFecha = fecha.split("-");

    return new Date(
      +datosFecha[0],
      +datosFecha[1] - 1,
      +datosFecha[2].substring(0, 2)
    );
  } else return new Date();
};

/**Convierte una fecha en un texto con el formato especificado*/
export const FormatearFecha = (
  fecha: Date,
  formato: "YYYY-MM-DD" | "DD-MM-YYYY" | "MM-DD-YYYY"
): string => {
  if (fecha) {
    if (formato === "YYYY-MM-DD") {
      return (
        fecha.getFullYear().toString() +
        "-" +
        (fecha.getMonth() + 1 < 10 ? "0" : "") +
        (fecha.getMonth() + 1).toString() +
        "-" +
        (fecha.getDate() < 10 ? "0" : "") +
        fecha.getDate().toString()
      );
    } else if (formato === "DD-MM-YYYY") {
      return (
        (fecha.getDate() < 10 ? "0" : "") +
        fecha.getDate().toString() +
        "-" +
        (fecha.getMonth() + 1 < 10 ? "0" : "") +
        (fecha.getMonth() + 1).toString() +
        "-" +
        fecha.getFullYear().toString()
      );
    } else if (formato === "MM-DD-YYYY") {
      return (
        (fecha.getMonth() + 1 < 10 ? "0" : "") +
        (fecha.getMonth() + 1).toString() +
        "-" +
        (fecha.getDate() < 10 ? "0" : "") +
        fecha.getDate().toString() +
        "-" +
        fecha.getFullYear().toString()
      );
    } else return fecha + "";
  } else return "";
};

/**Recibe un arreglo y devuelve el mismo con la propiedad fechaFormateada de la fecha
 * @param {Array} data arreglo con un campo fecha el cual se convertira a texto
 */
export const FormatearFechaArray = (data: any[]): any[] => {
  if (data && data.length) {
    return data.map((elemento) => {
      if (elemento.fecha)
        try {
          elemento.fechaFormateada = FormatearFecha(
            convertirTextoAFecha(elemento.fecha),
            "DD-MM-YYYY"
          );
        } catch {
          elemento.fechaFormateada = elemento.fecha;
        }

      return elemento;
    });
  } else return data;
};

/**Arreglo de meses */
export const Meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

/**Recibe el mes en numero y devuelve el nombre */
export const NombreMes = (mes: number) => {
  switch (mes) {
    case 1:
      return "Enero";
    case 2:
      return "Febrero";
    case 3:
      return "Marzo";
    case 4:
      return "Abril";
    case 5:
      return "Mayo";
    case 6:
      return "Junio";
    case 7:
      return "Julio";
    case 8:
      return "Agosto";
    case 9:
      return "Septiembre";
    case 10:
      return "Octubre";
    case 11:
      return "Noviembre";
    case 12:
      return "Diciembre";
    default:
      return "Mes no Existe";
  }
};

/**
 * devuelve un arreglo con los registros unicos segun campo unico
 */
export const RegistrosUnicos = (
  arregloOriginal: any[],
  campoUnico: string
): any[] => {
  const registrosUnicos: any[] = [];

  if (arregloOriginal) {
    arregloOriginal.forEach((registro) => {
      if (
        registrosUnicos.every(
          (elemento) => elemento[campoUnico] !== registro[campoUnico]
        )
      ) {
        registrosUnicos.push(registro);
      }
    });
  }

  return registrosUnicos;
};

export const numeroALetra = (numero: number): string => {
  let valor = 0;
  let texto = "";
  let comodin = "";
  const unidades = [
    "",
    "uno",
    "dos",
    "tres",
    "cuatro",
    "cinco",
    "seis",
    "siete",
    "ocho",
    "nueve",
    "diez",
    "once",
    "doce",
    "trece",
    "catorce",
    "quince",
    "dieciseis",
    "diecisiete",
    "dieciocho",
    "diecinueve",
  ];
  const decenas = [
    "",
    "diez",
    "veinte",
    "treinta",
    "cuarenta",
    "cincuenta",
    "sesenta",
    "setenta",
    "ochenta",
    "noventa",
  ];

  valor = Math.floor(numero / 1000000);
  numero = numero - valor * 1000000;
  if (valor === 1) texto = `${texto} un millon`;
  else if (valor > 1) texto = `${texto} ${unidades[valor]} millones`;

  valor = Math.floor(numero / 100000);
  numero = numero - valor * 100000;
  if (valor === 1) texto = `${texto} cien${numero > 1000 ? "to" : ""}`;
  else if (valor === 5) texto = `${texto} quinientos`;
  else if (valor === 7) texto = `${texto} setecientos`;
  else if (valor === 9) texto = `${texto} novecientos`;
  else if (valor > 0) texto = `${texto} ${unidades[valor]} cientos`;
  if (valor > 0) comodin = "mil";

  valor = Math.floor(numero / 10000);
  if (valor >= 2) {
    numero = numero - valor * 10000;
    texto = `${texto} ${decenas[valor]}`;
  }
  if (valor > 0) comodin = "mil";

  valor = Math.floor(numero / 1000);
  numero = numero - valor * 1000;
  if (valor === 0) texto = `${texto} ${comodin}`;
  else if (valor === 1) texto = `${texto} un mil`;
  else if (valor > 1) texto = `${texto} ${unidades[valor]} mil`;

  valor = Math.floor(numero / 100);
  numero = numero - valor * 100;
  if (valor === 1) texto = `${texto} cien${numero > 0 ? "to" : ""}`;
  else if (valor === 5) texto = `${texto} quinientos`;
  else if (valor === 7) texto = `${texto} setecientos`;
  else if (valor === 9) texto = `${texto} novecientos`;
  else if (valor > 0) texto = `${texto} ${unidades[valor]} cientos`;

  valor = Math.floor(numero / 10);
  if (valor > 1) {
    texto = `${texto} ${decenas[valor]}`;
    numero = numero - valor * 10;

    valor = Math.floor(numero);
    if (numero >= 1) {
      texto = `${texto} y ${unidades[valor]}`;
      numero = numero - valor;
    }
  } else {
    valor = Math.floor(numero);
    texto = `${texto} ${unidades[valor]}`;
    numero = numero - valor;
  }

  numero = +(numero * 100).toFixed(2);
  texto = `${texto} CON ${numero > 10 ? numero : "0" + numero}/100`;

  return texto;
};

/**Calcula el monto a pagar por cuota del prestamo */
export const calculaMontoPago = (
  montoPrestamo: number,
  tasaInteresCuota: number,
  cantidadCuotas: number,
  tipoCalculo: number
) => {
  if (!montoPrestamo || !tasaInteresCuota || !cantidadCuotas) return 0;

  if (tipoCalculo === 0) {
    let potencia = 1;
    const tasaCalculo = tasaInteresCuota / 100;
    const denominadorComun = 1 + tasaCalculo;
    let i = 0;

    do {
      potencia *= denominadorComun;
      i += 1;
    } while (i !== +cantidadCuotas);

    const potencia2 = 1 / potencia,
      potencia3 = (1 - potencia2) / tasaCalculo;

    return montoPrestamo / potencia3;
  }

  if (tipoCalculo === 1) {
    return (
      (((+tasaInteresCuota * +montoPrestamo) / 100) * +cantidadCuotas +
        +montoPrestamo) /
      +cantidadCuotas
    );
  }

  return 0;
};

export const arregloCuotasPrestamo = (
  cantidadCuotas: number,
  montoPago: number,
  tasaInteresCuota: number,
  montoPrestamo: number,
  fechaInicio: string,
  tipoCalculo: number,
  frecuenciaId: number = 30
) => {
  let montoInicial = montoPrestamo;
  let fecha = fechaInicio;
  const cuotas = [];

  for (let cuotaNo = 1; cuotaNo <= cantidadCuotas; cuotaNo++) {
    const montoInteres = +(
        ((tipoCalculo === 0 ? montoInicial : montoPrestamo) *
          tasaInteresCuota) /
        100
      ).toFixed(8),
      montoCapital = +(montoPago - montoInteres).toFixed(8),
      montoFinal = +(montoInicial - montoCapital).toFixed(8);

    fecha = FormatearFecha(
      agregarDias(fecha, frecuenciaDias["f" + frecuenciaId]),
      "YYYY-MM-DD"
    );

    cuotas.push({
      cuotaNo,
      fecha,
      montoInicial,
      montoPago,
      montoInteres,
      montoCapital,
      montoMora: 0,
      montoFinal,
      montoInteresPagado: 0,
      montoCapitalPagado: 0,
      montoMoraPagado: 0,
      isPagado: false,
      montoInteresPendiente: 0,
      montoCapitalPendiente: 0,
      montoMoraPendiente: 0,
    });

    montoInicial = montoFinal;
  }

  return cuotas;
};

export const buscarRNCDgii = async (rncCedula: string) => {
  const consulta = await Consultar<IRNC>(
    `api/RNC/consultarRnc`,
    undefined,
    undefined,
    {
      rnc: rncCedula,
    }
  );

  if (consulta.length === 0) return "";

  return consulta[0].nombre;
};

export const now = (formato: "YYYY-MM-DD" | "DD-MM-YYYY" | "MM-DD-YYYY") => {
  const date = new Date();

  const [month, day, year, hour, minutes, seconds] = [
    date.getMonth() + 1,
    date.getDate(),
    date.getFullYear(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];

  const fecha = FormatearFecha(date, formato);

  return {
    date: fecha,
    month: month,
    day: day,
    year: year,
    hour: hour,
    minutes: minutes,
    seconds: seconds,
  };
};

/**
 * Determina cuanto es el largo de la impresion para que se distribuya en diferentes paginas. Cada linea del grid son 0.16in
 * @param cantidadRegistrosTabla Registros que se van a mostrar en la tabla
 * @returns Tamaño del area de impresion determinando paginas impresas
 */
export const tamañoImpresionLetter = (cantidadRegistrosTabla: number = 0) => {
  const margenesTopBottom = 0.8;
  const largoPagina = 11;
  const largoAreaImpresion = largoPagina - margenesTopBottom;
  const primerLimitePrimeraPagina = 42;
  const maximoRegistrosPrimeraPagina = 54;
  const maximoRegistrosOtrasPaginas = 62;

  let cantidadPaginas = 0;

  if (cantidadRegistrosTabla <= primerLimitePrimeraPagina) {
    cantidadPaginas = 1;
  } else if (cantidadRegistrosTabla <= maximoRegistrosPrimeraPagina * 2) {
    cantidadPaginas = 2;
  } else {
    cantidadPaginas = 2;
    cantidadPaginas += Math.ceil(
      (cantidadRegistrosTabla - maximoRegistrosPrimeraPagina * 2) /
        maximoRegistrosOtrasPaginas
    );
  }

  return largoAreaImpresion * cantidadPaginas;
};

/**
 * Determina cuanto es el largo de la impresion para que se distribuya en diferentes paginas. Cada linea del grid son 0.16in
 * @param cantidadRegistrosTabla Registros que se van a mostrar en la tabla
 * @returns Tamaño del area de impresion determinando paginas impresas
 */
export const tamañoImpresionLetter_2 = (cantidadRegistrosTabla: number = 0) => {
  const margenesTopBottom = 0.8;
  const largoPagina = 11;
  const largoAreaImpresion = largoPagina - margenesTopBottom;
  const primerLimitePrimeraPagina = 42;
  const maximoRegistrosPrimeraPagina = 54;
  const maximoRegistrosOtrasPaginas = 62;

  let cantidadPaginas = 0;

  if (cantidadRegistrosTabla <= primerLimitePrimeraPagina) {
    cantidadPaginas = 1;
  } else if (cantidadRegistrosTabla <= maximoRegistrosPrimeraPagina * 2) {
    cantidadPaginas = 2;
  } else {
    cantidadPaginas = 2;
    cantidadPaginas += Math.ceil(
      (cantidadRegistrosTabla - maximoRegistrosPrimeraPagina * 2) /
        maximoRegistrosOtrasPaginas
    );
  }

  return {
    largoPagina: largoAreaImpresion * cantidadPaginas,
    cantidadPaginas,
    posicionConteo: 255,
  };
};

/**
 * Determina cuanto es el largo de la impresion para que se distribuya en diferentes paginas. Cada linea del grid son 0.16in
 * @param cantidadRegistrosTabla Registros que se van a mostrar en la tabla
 * @returns Tamaño del area de impresion determinando paginas impresas
 */
export const tamañoImpresionLetterLandscape = (
  cantidadRegistrosTabla: number = 0
) => {
  const margenesTopBottom = 0.8;
  const largoPagina = 8.5;
  const largoAreaImpresion = largoPagina - margenesTopBottom;
  const primerLimitePrimeraPagina = 26;
  const maximoRegistrosPrimeraPagina = 39;
  const maximoRegistrosOtrasPaginas = 47;

  const remanenteOtrasPaginas =
    cantidadRegistrosTabla <= maximoRegistrosPrimeraPagina
      ? 1
      : cantidadRegistrosTabla - maximoRegistrosPrimeraPagina;

  return cantidadRegistrosTabla <= primerLimitePrimeraPagina
    ? largoAreaImpresion
    : largoAreaImpresion +
        largoAreaImpresion *
          (Math.ceil(remanenteOtrasPaginas / maximoRegistrosOtrasPaginas) || 1);
};

/**
 * Determina cuanto es el largo de la impresion para que se distribuya en diferentes paginas. Cada linea del grid son 0.1666,0.1842,0.1833in
 * @param cantidadRegistrosTabla Registros que se van a mostrar en la tabla
 * @returns Tamaño del area de impresion determinando paginas impresas
 */
export const tamañoImpresion85x55 = (cantidadRegistrosTabla: number = 0) => {
  const margenesTopBottom = 0.8;
  const largoPagina = 5.5;
  const largoAreaImpresion = largoPagina - margenesTopBottom;
  const primerLimitePrimeraPagina = 7;
  const maximoRegistrosPrimeraPagina = 18;
  const maximoRegistrosOtrasPaginas = 29;

  let cantidadPaginas = 0;

  if (cantidadRegistrosTabla <= primerLimitePrimeraPagina) {
    cantidadPaginas = 1;
  } else if (cantidadRegistrosTabla <= maximoRegistrosPrimeraPagina * 2) {
    cantidadPaginas = 2;
  } else {
    cantidadPaginas = 2;
    cantidadPaginas += Math.ceil(
      (cantidadRegistrosTabla - maximoRegistrosPrimeraPagina * 2) /
        maximoRegistrosOtrasPaginas
    );
  }

  return largoAreaImpresion * cantidadPaginas;
};

/**
 * Determina cuanto es el largo de la impresion para que se distribuya en diferentes paginas. Cada linea del grid son 0.1666,0.1842,0.1833in
 * @param cantidadRegistrosTabla Registros que se van a mostrar en la tabla
 * @returns Tamaño del area de impresion determinando paginas impresas
 */
export const tamañoImpresion85x55_2 = (cantidadRegistrosTabla: number = 0) => {
  const margenesTopBottom = 0.8;
  const largoPagina = 5.5;
  const largoAreaImpresion = largoPagina - margenesTopBottom;
  const primerLimitePrimeraPagina = 7;
  const maximoRegistrosPrimeraPagina = 18;
  const maximoRegistrosOtrasPaginas = 29;

  let cantidadPaginas = 0;

  if (cantidadRegistrosTabla <= primerLimitePrimeraPagina) {
    cantidadPaginas = 1;
  } else if (cantidadRegistrosTabla <= maximoRegistrosPrimeraPagina * 2) {
    cantidadPaginas = 2;
  } else {
    cantidadPaginas = 2;
    cantidadPaginas += Math.ceil(
      (cantidadRegistrosTabla - maximoRegistrosPrimeraPagina * 2) /
        maximoRegistrosOtrasPaginas
    );
  }

  return {
    largoPagina: largoAreaImpresion * cantidadPaginas,
    cantidadPaginas,
    posicionConteo: 115,
  };
};

/**
 * Determina cuanto es el largo de la impresion para que se distribuya en diferentes paginas. Cada linea del grid son 0.1666,0.1842,0.1833in
 * @param cantidadRegistrosTabla Registros que se van a mostrar en la tabla
 * @returns Tamaño del area de impresion determinando paginas impresas
 */
export const tamañoImpresion85x7 = (cantidadRegistrosTabla: number = 0) => {
  const margenesTopBottom = 0.8;
  const largoPagina = 7;
  const largoAreaImpresion = largoPagina - margenesTopBottom;
  const primerLimitePrimeraPagina = 15;
  const maximoRegistrosPrimeraPagina = 23;
  const maximoRegistrosOtrasPaginas = 32;

  let cantidadPaginas = 0;

  if (cantidadRegistrosTabla <= primerLimitePrimeraPagina) {
    cantidadPaginas = 1;
  } else if (cantidadRegistrosTabla <= maximoRegistrosPrimeraPagina * 2) {
    cantidadPaginas = 2;
  } else {
    cantidadPaginas = 2;
    cantidadPaginas += Math.ceil(
      (cantidadRegistrosTabla - maximoRegistrosPrimeraPagina * 2) /
        maximoRegistrosOtrasPaginas
    );
  }

  return largoAreaImpresion * cantidadPaginas;
};

/**
 * Determina cuanto es el largo de la impresion para que se distribuya en diferentes paginas. Cada linea del grid son 0.1666,0.1842,0.1833in
 * @param cantidadRegistrosTabla Registros que se van a mostrar en la tabla
 * @returns Tamaño del area de impresion determinando paginas impresas
 */
export const tamañoImpresion85x7_2 = (cantidadRegistrosTabla: number = 0) => {
  const margenesTopBottom = 0.8;
  const largoPagina = 7;
  const largoAreaImpresion = largoPagina - margenesTopBottom;
  const primerLimitePrimeraPagina = 15;
  const maximoRegistrosPrimeraPagina = 23;
  const maximoRegistrosOtrasPaginas = 32;

  let cantidadPaginas = 0;

  if (cantidadRegistrosTabla <= primerLimitePrimeraPagina) {
    cantidadPaginas = 1;
  } else if (cantidadRegistrosTabla <= maximoRegistrosPrimeraPagina * 2) {
    cantidadPaginas = 2;
  } else {
    cantidadPaginas = 2;
    cantidadPaginas += Math.ceil(
      (cantidadRegistrosTabla - maximoRegistrosPrimeraPagina * 2) /
        maximoRegistrosOtrasPaginas
    );
  }

  return {
    largoPagina: largoAreaImpresion * cantidadPaginas,
    cantidadPaginas,
    posicionConteo: 153,
  };
};

export const toCamelCase = (texto: string) => {
  const inputArray = texto.toLocaleLowerCase().split(" ");

  let result = "";

  for (let i = 0, len = inputArray.length; i < len; i++) {
    const currentStr = inputArray[i];

    let tempStr = currentStr.toLowerCase();

    if (i !== 0) {
      // convert first letter to upper case (the word is in lowercase)
      tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
    }

    result += tempStr;
  }

  return result;
};

export const calcularPorcentaje = (
  total: number,
  monto: number,
  formatString = false
): number | string => {
  const result = +((monto / (total || 1)) * 100).toFixed(2);

  if (formatString) return FormatearNumero.format(result);

  return result;
};

/**Asigna el documento a editar con infomacion de evento */
export const asignarEventoDocumento = <T extends Record<string, any>>(
  documentoNuevo: T,
  username: string
): T => {
  if (documentoNuevo.evento) delete documentoNuevo.evento;
  if (documentoNuevo.tableData) delete documentoNuevo.tableData;

  return {
    ...documentoNuevo,
    evento: {
      usuario: username,
      estadoAnterior: documentoNuevo.id
        ? JSON.stringify(documentoNuevo)
        : undefined,
    },
  };
};

export const parseInputTarget = (target: {
  type: string;
  value: string;
  checked?: boolean;
}) => {
  const { type, value, checked } = target;

  if (type === "checkbox") {
    return checked ?? false;
  }

  if (
    type === "number" &&
    (value.length === 0 || value.length === 1 || +value !== 0)
  ) {
    return +(value || 0);
  }

  return value;
};
