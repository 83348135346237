import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { localStorageName } from "src/components/MainComponent";

const baseUrl =
  (process.env.NODE_ENV as string) === "production_ideecop"
    ? process.env.REACT_APP_API_URL_PROD_IDECOOP
    : process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

const customBaseQuery = (baseUrl: string) => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      // const token = getState().auth.token;
      const token = JSON.parse(
        localStorage.getItem(localStorageName) ?? "{}"
      ).tokenActual;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  });
};

export const continuoApi = createApi({
  reducerPath: "continuoApi",
  baseQuery: customBaseQuery(baseUrl ?? ""),
  endpoints: () => ({}),
});
