import React, { useRef } from "react";
import clsx from "clsx";
import {
  AppBar,
  Drawer,
  IconButton,
  Toolbar,
  Grid,
  Slide,
} from "@material-ui/core";
import { Menu, MenuOpen } from "@mui/icons-material";
import { withStyles } from "@material-ui/core/styles";
import { Styles } from "./NavBarStyles";
import { Ejecutar } from "../common/server/funcionesServidor";
import Notificaciones from "./Notificaciones/Notificaciones";
import Logo from "./Logo/Logo";
import Avatar from "./Avatar/Avatar";
import NavLeft from "./NavLeft/NavLeft";
import { IAuthContext } from "../../AppInterfaces";
import { useState } from "react";
import { useEffect } from "react";

const NavBar = ({
  children,
  classes,
  onCambiarCompania,
  LogoutUser,
  loginState: {
    id: usuarioId,
    username,
    imagen,
    avatar,
    ImagenesDefault: { rutaAvatarDefault },
    companiaSeleccionada: {
      id: companiaId,
      logo: companiaLogo,
      icono: companiaIcono,
      nombre: companiaNombre,
      r,
      g,
      b,
      a,
    },
  },
}: {
  children: JSX.Element;
  classes: any;
  onCambiarCompania: () => void;
  LogoutUser: () => void;
  loginState: IAuthContext;
}) => {
  const [menuAbierto, setMenuAbierto] = useState(true);
  const [mostrarAppBar, setMostrarAppBar] = useState(true);
  const scrollActual = useRef(0);

  const handleResize = () => {
    if (window.innerWidth <= 1280) {
      setMenuAbierto(false);
    }
  };

  const handleDrawerToggle = () => {
    setMenuAbierto((menuAbierto) => !menuAbierto);
  };

  //Habilita el periodo contable actual
  useEffect(() => {
    if (companiaId !== 0) {
      Ejecutar(
        "api/ContabilidadPeriodoContableAbiertos/PeriodoContableActual",
        { companiaId }
      );
    }
  }, [companiaId]);

  //Seguimiento a tamaño de pantalla para ocultar automatico el menu cuando es movil
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Seguimiento al scroll de la pantalla
  useEffect(() => {
    window.onscroll = () => {
      if (scrollActual.current < window.pageYOffset) {
        setMostrarAppBar(false);
      }

      if (scrollActual.current > window.pageYOffset) {
        setMostrarAppBar(true);
      }

      scrollActual.current = window.pageYOffset;
    };
  }, []);

  return (
    <div className={classes.root}>
      <Slide appear={true} timeout={500} direction="down" in={mostrarAppBar}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: menuAbierto,
          })}
        >
          <Toolbar className={classes.toolbars}>
            <span id="botonMenu">
              <IconButton
                color="inherit"
                aria-label="open close drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
                style={{ backgroundColor: `rgba(${r}, ${g}, ${b}, ${a})` }}
              >
                {menuAbierto ? <MenuOpen /> : <Menu />}
              </IconButton>
            </span>

            <span id="logo" style={{ height: "5rem" }}>
              <Logo
                RutaLogoCompania={companiaLogo ?? ""}
                RutaIconoCompania={companiaIcono ?? ""}
                nombreCompania={companiaNombre}
              />
            </span>

            <div className={classes.contenedorToolbar}>
              <span id="notificaciones">
                <Notificaciones usuarioId={usuarioId} />
              </span>

              <span className={classes.lineaDivisora} />

              <span id="avatar">
                <Avatar
                  NombreUsuario={username}
                  imgUsuario={imagen ?? ""}
                  avatarUsuario={avatar ?? ""}
                  rutaAvatarDefault={rutaAvatarDefault}
                  LogoutUser={LogoutUser}
                  authCompaniaSeleccionadaActualizar={onCambiarCompania}
                />
              </span>
            </div>
          </Toolbar>
        </AppBar>
      </Slide>

      <nav className={classes.drawer} id="menu">
        <Drawer
          open={menuAbierto}
          variant="persistent"
          anchor="left"
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          <NavLeft handleResize={handleResize} />
        </Drawer>
      </nav>

      <main
        id="main"
        className={clsx(classes.content, {
          [classes.contentShift]: menuAbierto,
        })}
      >
        <div className={classes.toolbar} />
        <Grid item container xs={12} className={classes.contenedor}>
          {children}
        </Grid>
      </main>
    </div>
  );
};

export default withStyles(Styles)(NavBar);
