import React, { useState, useEffect, useCallback } from "react";
import { useStyles } from "./Styles";
import { useNavigate } from "react-router-dom";
import { UncontrolledCollapse } from "reactstrap";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { KeyboardArrowDown } from "@mui/icons-material";
import { IModulo } from "../../../AppInterfaces";
import { Consultar } from "../../common/server/funcionesServidor";
import { useContext } from "react";
import { authContext } from "../../MainComponent";
import { fondo2 } from "../../../imagenes";
import { useDocument } from "src/redux/hooks";
import Dialog from "src/components/common/dialog/dialog";
import { Box } from "@mui/material";

export default function NavLeft({
  handleResize,
}: {
  handleResize: () => void;
}) {
  const {
    document: { isEditing },
  } = useDocument();

  const navigate = useNavigate();

  const classes = useStyles();

  const {
    companiaSeleccionada: { modulos: modulosDeCompania, r, g, b, a },
    accesos: accesosDelUsuario,
    roleNombre,
    realm,
  } = useContext(authContext);

  const [modulos, setModulos] = useState<IModulo[]>([]);

  const [confirmarAccionNavegar, setConfirmarAccionNavegar] = useState("");

  const consultar = useCallback(async () => {
    setModulos(
      await Consultar(`api/Modulos/consultar`, undefined, undefined, {
        order: "orden ASC",
      })
    );
  }, []);

  useEffect(() => {
    consultar();
  }, [consultar]);

  const handleLinkClick = (link: string) => {
    if (isEditing) {
      return setConfirmarAccionNavegar(link);
    }

    navigate(link);
    handleResize();
  };

  const navegarDialogBtn1 = () => {
    navigate(confirmarAccionNavegar);
    handleResize();
    setConfirmarAccionNavegar("");
  };

  const navegarDialogBtn2 = () => {
    setConfirmarAccionNavegar("");
  };

  const accesosMenu = () => {
    return modulos
      ? modulos
          .filter((modulo) =>
            accesosDelUsuario.some((acceso) => acceso.moduloId === modulo.id)
          )
          .map((modulo, index) =>
            modulosDeCompania.some(
              (moduloC) => moduloC.moduloId === modulo.id
            ) ? (
              <React.Fragment key={modulo.id}>
                <Box
                  className={classes.NavLink}
                  style={{
                    left: -100,
                    position: "relative",
                  }}
                >
                  <ListItem
                    button
                    id={modulo.nombre.replace(/\s/g, "")}
                    classes={{
                      root: `${classes.ListItem} ${classes.BorderMain}`,
                    }}
                    style={{
                      animation: `slide-right 0.5s cubic-bezier(0.680, -0.550, 0.265, 1.550) ${
                        index * 0.1
                      }s both`,
                      WebkitAnimation: `slide-right 0.5s cubic-bezier(0.680, -0.550, 0.265, 1.550) ${
                        index * 0.1
                      }s both`,
                    }}
                  >
                    <ListItemIcon classes={{ root: classes.ListIcon }}>
                      <span className={modulo.icono} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.ListText }}
                      primary={modulo.nombre}
                    />
                    <ListItemIcon classes={{ root: classes.ListIcon }}>
                      <KeyboardArrowDown />
                    </ListItemIcon>
                  </ListItem>
                </Box>

                <UncontrolledCollapse
                  toggler={modulo.nombre.replace(/\s/g, "")}
                >
                  {modulo.accesos.map((acceso) =>
                    accesosDelUsuario.some(
                      (accesoU) => accesoU.accesoId === acceso.id
                    ) ? (
                      <ListItem
                        key={acceso.id}
                        button
                        classes={{
                          root: `${classes.ListItem} ${classes.SubmenuLink} ${
                            acceso.nivel === 1 ? classes.nivel1 : ""
                          }`,
                        }}
                        onClick={() => handleLinkClick(acceso.link ?? "")}
                      >
                        <ListItemIcon classes={{ root: classes.ListIcon }}>
                          <span className={acceso.icono} />
                        </ListItemIcon>

                        <ListItemText
                          classes={{
                            primary:
                              acceso.nivel === 0
                                ? classes.ListText
                                : acceso.nivel === 1
                                  ? classes.ListTextNivel1
                                  : "",
                          }}
                          primary={acceso.nombre}
                        />
                      </ListItem>
                    ) : null
                  )}
                </UncontrolledCollapse>
              </React.Fragment>
            ) : null
          )
      : null;
  };

  return (
    <div
      className={classes.Contenedor}
      style={{
        backgroundColor: `rgba(${r},${g},${b},${a})`,
        backgroundImage: `url("${fondo2}")`,
        overflowY: "auto",
      }}
    >
      <List classes={{ root: classes.List }}>
        <ListItem
          button
          classes={{ root: classes.ListItem }}
          onClick={() => handleLinkClick("Dashboard")}
        >
          <ListItemIcon classes={{ root: classes.ListIcon }}>
            <span className="fas fa-chart-pie" />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.ListText }}
            primary="DashBoard"
          />
        </ListItem>

        {roleNombre === "sysadmin" ? (
          <>
            <ListItem
              id="configuraciones"
              button
              classes={{ root: `${classes.ListItem} ${classes.BorderMain}` }}
            >
              <ListItemIcon classes={{ root: classes.ListIcon }}>
                <span className="fas fa-wrench" />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.ListText }}
                primary="Configuraciones"
              />
              <ListItemIcon classes={{ root: classes.ListIcon }}>
                <KeyboardArrowDown />
              </ListItemIcon>
            </ListItem>

            <UncontrolledCollapse toggler="configuraciones">
              <ListItem
                button
                classes={{
                  root: `${classes.ListItem} ${classes.SubmenuLink}`,
                }}
                onClick={() => handleLinkClick("Usuarios")}
              >
                <ListItemIcon classes={{ root: classes.ListIcon }}>
                  <span className="fas fa-users-cog" />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.ListText }}
                  primary="Usuarios y Accesos"
                />
              </ListItem>
              <ListItem
                button
                classes={{
                  root: `${classes.ListItem} ${classes.SubmenuLink}`,
                }}
                onClick={() => handleLinkClick("Compania")}
              >
                <ListItemIcon classes={{ root: classes.ListIcon }}>
                  <span className="fas fa-home" />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.ListText }}
                  primary="Compañías"
                />
              </ListItem>

              <ListItem
                button
                classes={{
                  root: `${classes.ListItem} ${classes.SubmenuLink}`,
                }}
                onClick={() => handleLinkClick("Importaciones")}
              >
                <ListItemIcon classes={{ root: classes.ListIcon }}>
                  <span className="fas fa-file-import" />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.ListText }}
                  primary="Importaciones"
                />
              </ListItem>

              <ListItem
                button
                classes={{
                  root: `${classes.ListItem} ${classes.SubmenuLink}`,
                }}
                onClick={() => handleLinkClick("Eventos")}
              >
                <ListItemIcon classes={{ root: classes.ListIcon }}>
                  <span className="fas fa-list-alt" />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.ListText }}
                  primary="Eventos"
                />
              </ListItem>

              {realm === "1" && (
                <>
                  <ListItem
                    button
                    classes={{
                      root: `${classes.ListItem} ${classes.SubmenuLink}`,
                    }}
                    onClick={() => handleLinkClick("Companias")}
                  >
                    <ListItemIcon classes={{ root: classes.ListIcon }}>
                      <span className="fas fa-home" />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.ListText }}
                      primary="Compañias"
                    />
                  </ListItem>
                </>
              )}
            </UncontrolledCollapse>
          </>
        ) : null}

        {accesosMenu()}
      </List>

      <Dialog
        open={!!confirmarAccionNavegar}
        titulo="¡Advertencia!"
        estiloTitulo="Warning"
        mensaje="¿Seguro desea cancelar el proceso?, Existen cambios sin grabar si procede serán descartados."
        textoBtn1="Continuar y Descartar Cambios"
        textoBtn2="Cancelar"
        accionDialogBtn1={navegarDialogBtn1}
        accionDialogBtn2={navegarDialogBtn2}
      />
    </div>
  );
}
