import React, { useContext, useEffect, useState } from "react";
import MaterialTable, { Column, Filter, Options } from "@material-table/core";
import { Grid } from "@mui/material";
import {
  options,
  localization,
  cabezeras,
} from "../opcionesComunesMaterialTable/opcionesComunesMaterialTable";
import CabezeraInforme from "../cabezeraInforme/cabezeraInforme";
import { FormatearFechaArray, FormatearNumero } from "../funciones/funciones";
import { authContext } from "../../MainComponent";
import { BookLoader } from "../loader/loader";
import { IAuthContext } from "../../../AppInterfaces";

import "./DetalleGrid.css";

export default function DetalleGrid({
  tableRef,
  data = [],
  collection,
  onVisualizar,
  titulo = "",
  detailPanel,
  otrasOpciones = {},
  otrasAcciones = [],
  otrasLocalization = {},
  parentChildData,
  textoFiltros = "",
  cabezeraRemplazar = [],
  isLoading = false,
  loaderComponent,
  onSelectionChange,
  onRowClick,
  usarCabezeraInforme = true,
  onFilterChange,
  onSearchChange,
  usarLogo,
  showSummaryRow,
  mostrarPaginacion = true,
}: {
  tableRef?: React.RefObject<any> | React.MutableRefObject<undefined>;
  data: any[];
  collection: string;
  onVisualizar?: (registro: any) => void;
  titulo?: string;
  detailPanel?: (utilizaCentroCosto: boolean) => any[];
  otrasOpciones?: Options<any>;
  otrasAcciones?: any[];
  otrasLocalization?: object;
  parentChildData?: (row: any, rows: any) => void;
  textoFiltros?: string | React.ReactNode;
  cabezeraRemplazar?: Column<any>[];
  isLoading?: boolean;
  loaderComponent?: JSX.Element;
  onSelectionChange?: (data: any[], rowData?: any) => void;
  onRowClick?: (
    event?: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData?: any,
    toggleDetailPanel?: ((panelIndex?: number | undefined) => void) | undefined
  ) => void;
  usarCabezeraInforme?: boolean;
  onFilterChange?: (filters: Filter<any>[]) => void;
  onSearchChange?: (searchText: string) => void;
  usarLogo?: boolean;
  showSummaryRow?: boolean;
  mostrarPaginacion?: boolean;
}) {
  const localStorageHiddenFields = "continuoMaterialTableHiddenFields";
  const [columns, setColumns] = useState<any[]>([]);

  const {
    companiaSeleccionada: { utilizaCentroCosto, nombre: companiaNombre },
  } = useContext(authContext) as IAuthContext;

  const textoCabeza = `${companiaNombre}
${titulo} - ${textoFiltros}`;

  // Busca las columnas y le inserta la propiedad hidden si se localiza en el localstorage
  useEffect(() => {
    const newColumns = cabezeras(collection, utilizaCentroCosto);
    const hiddenFields = JSON.parse(
      localStorage.getItem(localStorageHiddenFields) || "{}"
    );

    if (newColumns && hiddenFields && hiddenFields[collection]) {
      for (let i = 0; i < newColumns.length; i++) {
        const column = newColumns[i];

        // Oculta las columnas que esten grabadas en el storage
        if (
          hiddenFields[collection].some((hid: string) => hid === column.field)
        ) {
          column.hidden = true;
        } else {
          column.hidden = false;
        }

        // Si la columna esta oculta desde el boton siempre debe ser hidden
        if (column.hiddenByColumnsButton) {
          column.hidden = true;
        }

        // Ajusta el minimo para los nombres
        if ((column.field as string)?.toUpperCase()?.includes("NOMBRE")) {
          column.cellStyle = {
            ...column.cellStyle,
            minWidth: 150,
          };
        }
      }
    }

    setColumns(newColumns);
  }, [collection, utilizaCentroCosto]);

  const onChangeColumnHidden = (column: Column<any>, hidden: boolean) => {
    const hiddenFields = JSON.parse(
      localStorage.getItem(localStorageHiddenFields) || "{}"
    );

    if (!hiddenFields[collection]) hiddenFields[collection] = [];

    if (hidden) {
      hiddenFields[collection].push(column.field);
    } else {
      hiddenFields[collection] = hiddenFields[collection].filter(
        (field: string) => field !== column.field
      );
    }

    localStorage.setItem(
      localStorageHiddenFields,
      JSON.stringify(hiddenFields)
    );
  };

  const renderSummaryRow = ({
    column,
    data,
  }: {
    column: Column<any>;
    data: any[];
  }) => {
    if (
      data &&
      data.length &&
      column.type === "numeric" &&
      column.field !== undefined &&
      !(column.field as string).toUpperCase().includes("CODIGO") &&
      !(column.field as string).toUpperCase().includes("AUXILIAR") &&
      !(column.field as string).toUpperCase().includes("BALANCE") &&
      !(column.field as string).toUpperCase().includes("TASA") &&
      !(column.field as string).toUpperCase().includes("CANTIDAD") &&
      !(column.field as string).toUpperCase().includes("DISPONIBILIDAD")
    ) {
      return {
        value: FormatearNumero.format(
          data.reduce(
            (acc, row) =>
              +(acc + +(row[column.field as string] ?? 0)).toFixed(2),
            0
          )
        ),
        style: { borderTop: "2px black solid" },
      };
    }

    return undefined;
  };

  return (
    <Grid item xs={12} id="materialTable">
      {usarCabezeraInforme && (
        <CabezeraInforme textoFiltros={textoFiltros} usarLogo={usarLogo} />
      )}

      <MaterialTable
        tableRef={tableRef}
        title={titulo}
        columns={cabezeraRemplazar.length ? cabezeraRemplazar : columns}
        data={FormatearFechaArray(isLoading ? [] : data)}
        parentChildData={parentChildData}
        options={{
          ...options(titulo, textoCabeza),
          paging: mostrarPaginacion && data.length > 50 ? true : false,
          pageSize: 50,
          pageSizeOptions: [5, 10, 20, 50, data.length],
          ...otrasOpciones,
        }}
        localization={{ ...localization, ...otrasLocalization }}
        onRowClick={
          onRowClick
            ? onRowClick
            : onVisualizar
              ? (_, rowData) => onVisualizar(rowData)
              : undefined
        }
        actions={[...otrasAcciones]}
        detailPanel={
          detailPanel ? detailPanel(utilizaCentroCosto ?? false) : undefined
        }
        components={{
          OverlayLoading: () =>
            loaderComponent ? loaderComponent : <BookLoader />,
        }}
        isLoading={isLoading}
        onSelectionChange={onSelectionChange}
        onFilterChange={onFilterChange}
        onSearchChange={onSearchChange}
        onChangeColumnHidden={onChangeColumnHidden}
        renderSummaryRow={showSummaryRow ? renderSummaryRow : undefined}
      />
    </Grid>
  );
}
