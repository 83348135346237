import React, {
  createContext,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ErrorBoundary } from "react-error-boundary";
import { useIdleTimer } from "react-idle-timer";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { queryClient } from "../";
import {
  AuthContextDefault,
  IAGlobalMoneda,
  IAuthContext,
  ICompania,
  ICompaniasConAcceso,
  ICxCCuadreCaja,
  IInfoLogin,
  IInventarioProducto,
  IToken,
  IUsuarioAcceso,
  IUsuarioRole,
} from "../AppInterfaces";
import { avatar, companiadefault, NoImage } from "../imagenes";
import MensajeAlerta from "./common/mensajeAlerta/mensajeAlerta";
import {
  Consultar,
  Ejecutar,
  Eliminar,
  GrabarCustom,
} from "./common/server/funcionesServidor";
import ErrorFallback from "./ErrorFallback";
import IdleAlert from "./IdleAlert";
import NavBar from "./NavBar/NavBar";
import Rutas from "./Rutas";

import "react-toastify/dist/ReactToastify.css";
import MontoCuadreCaja from "./montoCuadreCaja/MontoCuadreCaja";
import { FormatearFecha } from "./common/funciones/funciones";
import NoInternetConnection from "./NoInternetConnection";
import NuevaContrasena from "./Login/ResetearContrasena/NuevaContrasena";
import { CogLoader } from "./common/loader/loader";

const Login = lazy(() => import("./Login/Login"));
const SeleccionCompania = lazy(
  () => import("./Content/SeleccionCompania/SeleccionCompania")
);

export const authContext = createContext<IAuthContext>(AuthContextDefault);
export const localStorageName = "loginStateContinuo";

export default function Main() {
  const location = useLocation();

  const [loginState, setLoginState] =
    useState<IAuthContext>(AuthContextDefault);

  const [aditionalState, setAditionalState] =
    useState<Pick<IAuthContext, "globalMonedas">>();

  const [triggerActualizandoLoginState, setTriggerActualizandoLoginState] =
    useState(false);

  const navigate = useNavigate();

  const ImagenesDefault = useMemo(
    () => ({
      rutaNoImage: NoImage,
      rutaIconoClickteck: "logo192.png",
      rutaAvatarDefault: avatar,
      rutaImagenCompaniaDefault: companiadefault,
    }),
    []
  );

  /**Precargo los productos para cargar mas rapido las busquedas */
  useQuery(["InventarioProductos", loginState], async () => {
    if (loginState.companiaSeleccionada.id) {
      const productos = await Consultar<IInventarioProducto>(
        `api/InventarioProductos/consultar`,
        undefined,
        undefined,
        { companiaId: loginState.companiaSeleccionada.id }
      );

      queryClient.setQueryData("InventarioProductos", productos);

      return productos;
    }

    return [];
  });

  useQuery("AGlobalMonedas", async () => {
    const rs = await Consultar<IAGlobalMoneda>("api/AGlobalMonedas/consultar");
    setAditionalState({ ...aditionalState, globalMonedas: rs });

    return rs;
  });

  const onCambioColorCompania = useCallback(
    (color: Record<string, unknown>) => {
      const storage = localStorage.getItem(localStorageName);

      if (!storage) return;

      const loginState: IAuthContext = JSON.parse(storage);
      loginState.companiaSeleccionada = {
        ...loginState.companiaSeleccionada,
        ...color,
      };

      localStorage.setItem(localStorageName, JSON.stringify(loginState));

      setTriggerActualizandoLoginState(
        (triggerActualizandoLoginState) => !triggerActualizandoLoginState
      );
    },
    []
  );

  const onActualizarLoginState = useCallback(async () => {
    const storage = localStorage.getItem(localStorageName);

    if (!storage) return;

    const loginState: IAuthContext = JSON.parse(storage);

    Consultar<IUsuarioRole>("api/Usuarios/role", undefined, undefined, {
      campos: "roleId, roleNombre",
      usuarioId: loginState.id,
      companiaId: loginState.companiaSeleccionada.id,
    }).then(async (roleEnCompania) => {
      let roleId = roleEnCompania.length !== 0 ? roleEnCompania[0].roleId : 0;
      let roleNombre =
        roleEnCompania.length !== 0 ? roleEnCompania[0].roleNombre : "";

      if (loginState.realm === "1") {
        roleId = 1;
        roleNombre = "sysadmin";
      }

      Consultar<ICompania>(
        "api/Compania/consultarListado",
        undefined,
        undefined,
        {
          where: `id = ${loginState.companiaSeleccionada.id}`,
        }
      ).then(async (compania) => {
        const usuario = await Consultar<IInfoLogin>(
          "api/Usuarios/consultarInfoLogin",
          undefined,
          loginState.tokenActual,
          { where: `id = ${loginState.id}` }
        );

        const accesos = await Consultar<IUsuarioAcceso>(
          "api/Usuarios/accesos",
          undefined,
          undefined,
          {
            where: `${
              loginState.realm !== "1"
                ? `companiaId = ${compania[0].id} and usuarioId = ${loginState.id}`
                : ""
            }`,
          }
        );

        const newAuthContext: IAuthContext = {
          ...AuthContextDefault,
          ...loginState,
          ...usuario[0],
        };

        const companiaSelectInfo = usuario[0].companiasConAcceso.find(
          (companiaAcc) => companiaAcc.companiaId === compania[0].id
        );

        newAuthContext.tokenActual = loginState.tokenActual;
        newAuthContext.roleId = roleId;
        newAuthContext.roleNombre = roleNombre;
        newAuthContext.companiaSeleccionada = compania[0];
        newAuthContext.accesos = accesos;
        newAuthContext.almacenGrupoId = companiaSelectInfo?.almacenGrupoId ?? 0;
        newAuthContext.almacenGrupoNombre =
          companiaSelectInfo?.almacenGrupoNombre ?? "";

        newAuthContext.companiaSeleccionada.almacenesGrupos =
          newAuthContext.companiaSeleccionada.almacenesGrupos.filter(
            (almacenGrupo) =>
              newAuthContext.almacenGrupoId === 0 ||
              newAuthContext.almacenGrupoId === almacenGrupo.id
          );

        newAuthContext.companiaSeleccionada.almacenes =
          newAuthContext.companiaSeleccionada.almacenes.filter(
            (almacen) =>
              newAuthContext.almacenGrupoId === 0 ||
              newAuthContext.almacenGrupoId === almacen.grupoId
          );

        localStorage.setItem(localStorageName, JSON.stringify(newAuthContext));

        setTriggerActualizandoLoginState(
          (triggerActualizandoLoginState) => !triggerActualizandoLoginState
        );
      });
    });
  }, []);

  const getLoginState = async (userId: number, tokenActual: string) => {
    const usuario = await Consultar<IInfoLogin>(
      "api/Usuarios/consultarInfoLogin",
      undefined,
      tokenActual,
      { where: `id = ${userId}` }
    );

    if (!usuario.length) return;

    if (usuario[0].realm !== "1") {
      if (
        !usuario ||
        usuario.length === 0 ||
        usuario[0].companiasConAcceso.length === 0
      ) {
        MensajeAlerta(
          "error",
          "No tienes asignadas compañías para continuar. Contactate con el administrador de la compañía.",
          false
        );

        await Ejecutar(
          `api/Usuarios/logout?access_token=${tokenActual}`,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        );

        return;
      }
    }

    const newAuthContext: IAuthContext = {
      ...AuthContextDefault,
      ...usuario[0],
    };

    newAuthContext.tokenActual = tokenActual;

    localStorage.setItem(localStorageName, JSON.stringify(newAuthContext));

    setTriggerActualizandoLoginState(
      (triggerActualizandoLoginState) => !triggerActualizandoLoginState
    );
  };

  const onLogIn = async (creds: { email: string; password: string }) => {
    const auth = await Ejecutar<IToken>(
      "api/Usuarios/login",
      undefined,
      { ...creds, ttl: 86400 }, //especifico tiempo de vida del token 24 horas
      undefined,
      undefined,
      true
    );

    if (auth.length) getLoginState(auth[0].userId, auth[0].id);
  };

  const onLogOut = useCallback(async (razon: string = "LOGOUT") => {
    const storage = localStorage.getItem(localStorageName);

    if (!storage) return;

    const loginState: IAuthContext = JSON.parse(storage);

    await GrabarCustom(
      `api/AGlobalEventos/crearEvento`,
      {
        id: 0,
        companiaId: loginState.companiaSeleccionada.id,
        codigo: 0,
        eventoTipo: "LOGOUT",
        evento: {
          usuario: loginState.username,
        },
        userAgent: navigator.userAgent,
        razon,
      },
      true
    );

    await Ejecutar(
      `api/Usuarios/logout?access_token=${loginState.tokenActual}`,
      undefined,
      undefined,
      undefined,
      undefined,
      true
    );

    localStorage.setItem(localStorageName, JSON.stringify(AuthContextDefault));

    setTriggerActualizandoLoginState(
      (triggerActualizandoLoginState) => !triggerActualizandoLoginState
    );
  }, []);

  const { getRemainingTime, pause, resume } = useIdleTimer({
    timeout: 1000 * 60 * (+loginState.idleTime || 30),
    onIdle: () => onLogOut("INACTIVIDAD"),
    debounce: 1000,
    crossTab: true,
    name: "idleTimer",
    syncTimers: 1000,
  });

  const onEliminarOtrosToken = async () => {
    const nuevoEstado: IAuthContext = { ...loginState };

    nuevoEstado.tokens.forEach((token) => {
      if (token.id !== loginState.tokenActual) {
        Eliminar(`api/Usuarios/${loginState.id}/accessTokens`, token.id, true);
      }
    });

    if (loginState.tokenActual) {
      nuevoEstado.tokens = [
        { id: loginState.tokenActual, ttl: 0, created: "", userId: 0 },
      ];
    }

    localStorage.setItem(localStorageName, JSON.stringify(nuevoEstado));

    setTriggerActualizandoLoginState(
      (triggerActualizandoLoginState) => !triggerActualizandoLoginState
    );
  };

  const onSeleccionarCompania = (compania: ICompaniasConAcceso) => {
    if (compania.isInactivo) {
      MensajeAlerta(
        "error",
        "Esta compañía ha sido deshabilitada, por favor contactarse con Clickteck al 809-567-1916.",
        false
      );
      return;
    }

    Consultar<IUsuarioRole>("api/Usuarios/role", undefined, undefined, {
      campos: "roleId, roleNombre",
      usuarioId: loginState.id,
      companiaId: compania.companiaId,
    }).then(async (roleEnCompania) => {
      let roleId = roleEnCompania.length !== 0 ? roleEnCompania[0].roleId : 0;
      let roleNombre =
        roleEnCompania.length !== 0 ? roleEnCompania[0].roleNombre : "";

      if (!roleEnCompania.length) {
        if (loginState.realm !== "1") {
          MensajeAlerta(
            "error",
            "No tienes rol asignado en la compañía indicada. Contactate con tu administrador de sistema.",
            false
          );
          return;
        }

        roleId = 1;
        roleNombre = "sysadmin";
      }

      const compania1 = await Consultar<ICompania>(
        "api/Compania/consultarListado",
        undefined,
        undefined,
        {
          where: `id = ${compania.companiaId}`,
        }
      );

      const accesos = await Consultar<IUsuarioAcceso>(
        "api/Usuarios/accesos",
        undefined,
        undefined,
        {
          where: `${
            loginState.realm !== "1"
              ? `companiaId = ${compania1[0].id} and usuarioId = ${loginState.id}`
              : ""
          }`,
        }
      );

      let cuadreCajaId = 0,
        cuadreCajaCodigo = 0,
        cuadreCajaMontoInicio = 0;

      if (compania.utilizaCuadreCaja) {
        const cuadresCaja = await Consultar<ICxCCuadreCaja>(
          "api/CxCCuadresCaja/consultar",
          undefined,
          undefined,
          {
            where: `usuarioId = ${loginState.id} and companiaId = ${compania1[0].id} and isCerrado = 0`,
          }
        );

        if (cuadresCaja.length) {
          MensajeAlerta(
            "warning",
            "Existe un cuadre de caja sin Liquidar. Está usando el cuadre anterior.",
            false
          );
          cuadreCajaId = cuadresCaja[0].id ?? 0;
          cuadreCajaCodigo = cuadresCaja[0].codigo ?? 0;
          cuadreCajaMontoInicio = cuadresCaja[0].montoInicio;
        }
      }

      compania1[0].almacenesGrupos = compania1[0].almacenesGrupos.filter(
        (almacenGrupo) =>
          compania.almacenGrupoId === 0 ||
          compania.almacenGrupoId === almacenGrupo.id
      );

      compania1[0].almacenes = compania1[0].almacenes.filter(
        (almacen) =>
          compania.almacenGrupoId === 0 ||
          compania.almacenGrupoId === almacen.grupoId
      );

      await GrabarCustom(
        `api/AGlobalEventos/crearEvento`,
        {
          id: 0,
          companiaId: compania1[0].id,
          codigo: 0,
          eventoTipo: "LOGIN",
          evento: {
            usuario: loginState.username,
          },
          navigator: navigator,
        },
        true
      );

      localStorage.setItem(
        localStorageName,
        JSON.stringify({
          ...loginState,
          roleId,
          roleNombre,
          companiaSeleccionada: compania1[0],
          accesos,
          utilizaCuadreCaja: compania.utilizaCuadreCaja,
          almacenGrupoId: compania.almacenGrupoId,
          almacenGrupoNombre: compania.almacenGrupoNombre,
          cuadreCajaId,
          cuadreCajaCodigo,
          cuadreCajaMontoInicio,
        })
      );

      setTriggerActualizandoLoginState(
        (triggerActualizandoLoginState) => !triggerActualizandoLoginState
      );
    });
  };

  const onCambiarCompania = () => {
    localStorage.setItem(
      localStorageName,
      JSON.stringify({
        ...loginState,
        companiaSeleccionada: { ...loginState.companiaSeleccionada, id: 0 },
      })
    );

    setTriggerActualizandoLoginState(
      (triggerActualizandoLoginState) => !triggerActualizandoLoginState
    );
  };

  useEffect(() => {
    // localStorage.removeItem(localStorageName);
    const storage = localStorage.getItem(localStorageName);

    if (!storage) return;

    const loginState: IAuthContext = JSON.parse(storage);
    loginState.onCambioColorCompania = onCambioColorCompania;
    loginState.onActualizarLoginState = onActualizarLoginState;
    loginState.onLogOut = onLogOut;
    loginState.ImagenesDefault = ImagenesDefault;
    setLoginState(loginState);
    // console.log(loginState);
  }, [
    onActualizarLoginState,
    onCambioColorCompania,
    onLogOut,
    triggerActualizandoLoginState,
    ImagenesDefault,
  ]);

  //Pausa o reinicia idler si usuario esta logeado
  useEffect(() => {
    if (!loginState.id) pause();
    else resume();
  }, [pause, resume, loginState]);

  const aplicarMontoCuadreCaja = async (monto: number) => {
    const cuadreCaja = await GrabarCustom<ICxCCuadreCaja>(
      `api/CxCCuadresCaja/crearModificar`,
      {
        evento: { usuario: loginState.username },
        companiaId: loginState.companiaSeleccionada.id,
        usuarioId: loginState.id,
        usuarioNombre: loginState.username,
        fechaInicio: FormatearFecha(new Date(), "YYYY-MM-DD"),
        isCerrado: false,
        montoInicio: monto,
        efectivo1: 0,
        efectivo10: 0,
        efectivo100: 0,
        efectivo1000: 0,
        efectivo200: 0,
        efectivo2000: 0,
        efectivo25: 0,
        efectivo5: 0,
        efectivo50: 0,
        efectivo500: 0,
        efectivoEnCaja: 0,
        efectivoTotal: 0,
        distribucionIngresos: [],
        creditosClientes: [],
        descuentosDevoluciones: [],
      }
    );

    if (cuadreCaja.length) {
      localStorage.setItem(
        localStorageName,
        JSON.stringify({
          ...loginState,
          cuadreCajaId: cuadreCaja[0].id,
          cuadreCajaCodigo: cuadreCaja[0].codigo,
          cuadreCajaMontoInicio: monto,
        })
      );

      setTriggerActualizandoLoginState(
        (triggerActualizandoLoginState) => !triggerActualizandoLoginState
      );
    }
  };

  const pantallaMostrar = () => {
    // Nueva contraseña
    if (location.pathname.includes("reset-password")) {
      return (
        <Suspense fallback={<div>Cargando...</div>}>
          <NuevaContrasena />
        </Suspense>
      );
    }

    //Login
    if (
      loginState.tokens.length !== 1 ||
      loginState.companiasConAcceso.length === 0
    ) {
      return (
        <Suspense fallback={<div>Cargando...</div>}>
          <Login
            RutaIcono={ImagenesDefault.rutaIconoClickteck}
            onLogIn={onLogIn}
            onLogOut={onLogOut}
            onEliminarOtrosToken={onEliminarOtrosToken}
            tokensDelUsuario={loginState?.tokens ?? []}
          />
        </Suspense>
      );
    }

    //Autoseleccion de compania unica
    if (
      loginState.companiasConAcceso.length === 1 &&
      !loginState.companiaSeleccionada.id
    ) {
      onSeleccionarCompania(loginState.companiasConAcceso[0]);

      return <CogLoader />;
    }

    //Seleccion de compania
    if (loginState.companiaSeleccionada.id === 0) {
      return (
        <Suspense fallback={<div>Cargando...</div>}>
          <SeleccionCompania
            companiasAcceso={loginState.companiasConAcceso}
            seleccionarCompania={onSeleccionarCompania}
            rutaImagenCompaniaDefault={
              ImagenesDefault.rutaImagenCompaniaDefault
            }
          />
        </Suspense>
      );
    }

    //Solicitando monto inicio caja
    if (
      loginState.utilizaCuadreCaja &&
      !loginState.cuadreCajaId &&
      !loginState.cuadreCajaMontoInicio
    ) {
      return (
        <MontoCuadreCaja onConfirm={(monto) => aplicarMontoCuadreCaja(monto)} />
      );
    }

    return (
      <authContext.Provider value={{ ...loginState, ...aditionalState }}>
        <NavBar
          loginState={loginState}
          LogoutUser={onLogOut}
          onCambiarCompania={onCambiarCompania}
        >
          <Rutas />
        </NavBar>
      </authContext.Provider>
    );
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        navigate("DashBoard");
      }}
    >
      <IdleAlert getRemainingTime={getRemainingTime} />

      <NoInternetConnection />

      {pantallaMostrar() || null}
    </ErrorBoundary>
  );
}
