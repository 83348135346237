import React, { useState } from "react";
import classes from "./Avatar.module.css";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DialogMessage from "../../common/dialog/dialog";
import { useNavigate } from "react-router-dom";
import {
  Person,
  PowerSettingsNew,
  Business,
  Folder,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import * as Avatares from "../../common/avatares/avatares";
import { useDocument } from "src/redux/hooks";
import Dialog from "../../common/dialog/dialog";

/**
 * presenta la seccion del avatar del usuario junto a sus opciones
 * @param NombreUsuario nombre del usuario a mostrar
 * @param imgUsuario imagen del usuario a mostrar
 * @param LogoutUser metodo que desconecta el usuario
 * @param authCompaniaSeleccionadaActualizar metodo para cambiar de compañía
 */
export default function Avatar({
  authCompaniaSeleccionadaActualizar,
  LogoutUser,
  NombreUsuario,
  imgUsuario,
  avatarUsuario,
  rutaAvatarDefault,
}: {
  authCompaniaSeleccionadaActualizar: () => void;
  LogoutUser: () => void;
  NombreUsuario: string;
  imgUsuario: string;
  avatarUsuario: string;
  rutaAvatarDefault: string;
}) {
  const {
    document: { isEditing },
  } = useDocument();

  const navigate = useNavigate();

  const [MostrarDialogSalir, setMostrarDialogSalir] = useState(false);
  const [confirmarAccionCambiar, setConfirmarAccionCambiar] = useState(false);
  const [confirmarAccionPerfil, setConfirmarAccionPerfil] = useState(false);

  const handlePerfilClick = () => {
    if (isEditing) {
      return setConfirmarAccionPerfil(true);
    }

    navigate("Perfil");
  };

  const handleCambiarCompaniaClick = () => setConfirmarAccionCambiar(true);

  const handleContinuoDocsClick = () =>
    window.open("https://clickteckrd.com/continuoDocs/");

  const handleSalirClick = () => setMostrarDialogSalir(true);

  const salirDialogBtn1 = () => {
    setMostrarDialogSalir(false);
    LogoutUser();
  };

  const salirDialogBtn2 = () => {
    setMostrarDialogSalir(false);
  };

  const cambiarDialogBtn1 = () => {
    setConfirmarAccionCambiar(false);
    authCompaniaSeleccionadaActualizar();
  };

  const cambiarDialogBtn2 = () => {
    setConfirmarAccionCambiar(false);
  };

  const perfilDialogBtn1 = () => {
    setConfirmarAccionPerfil(false);
    navigate("Perfil");
  };

  const perfilDialogBtn2 = () => {
    setConfirmarAccionPerfil(false);
  };

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle tag="span">
          <span className={classes.nomUsuario}>{NombreUsuario}</span>
          {imgUsuario ? (
            <img
              className={classes.imgProfile}
              src={"data:image/png;base64," + imgUsuario}
              alt="Imagen Usuario"
            />
          ) : avatarUsuario === "avatar1" ? (
            <IconButton>{Avatares.avatar1(45, 45)}</IconButton>
          ) : avatarUsuario === "avatar2" ? (
            <IconButton>{Avatares.avatar2(45, 45)}</IconButton>
          ) : avatarUsuario === "avatar3" ? (
            <IconButton>{Avatares.avatar3(45, 45)}</IconButton>
          ) : avatarUsuario === "avatar4" ? (
            <IconButton>{Avatares.avatar4(45, 45)}</IconButton>
          ) : avatarUsuario === "avatar5" ? (
            <IconButton>{Avatares.avatar5(45, 45)}</IconButton>
          ) : avatarUsuario === "avatar6" ? (
            <IconButton>{Avatares.avatar6(45, 45)}</IconButton>
          ) : (
            <img
              className={classes.imgProfile}
              src={rutaAvatarDefault}
              alt="Imagen Usuario"
            />
          )}
        </DropdownToggle>

        <DropdownMenu align="end">
          <DropdownItem
            className={classes.opciones}
            onClick={handlePerfilClick}
          >
            <Person fontSize="small" className={classes.iconos} />
            Mi Perfil
          </DropdownItem>

          <DropdownItem
            className={classes.opciones}
            onClick={handleCambiarCompaniaClick}
          >
            <Business fontSize="small" className={classes.iconos} />
            Cambiar Compañía
          </DropdownItem>

          <DropdownItem
            className={classes.opciones}
            onClick={handleContinuoDocsClick}
          >
            <Folder fontSize="small" className={classes.iconos} />
            Documentación
          </DropdownItem>

          <DropdownItem divider />

          <DropdownItem className={classes.opciones} onClick={handleSalirClick}>
            <PowerSettingsNew fontSize="small" className={classes.iconos} />
            Salir del Sistema
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      <DialogMessage
        open={MostrarDialogSalir}
        titulo="¡Advertencia!"
        estiloTitulo="Warning"
        mensaje="¿Seguro desea salir de la aplicación?"
        textoBtn1="Salir de Aplicación"
        textoBtn2="Cancelar"
        accionDialogBtn1={salirDialogBtn1}
        accionDialogBtn2={salirDialogBtn2}
      />

      <Dialog
        open={confirmarAccionCambiar}
        titulo="¡Advertencia!"
        estiloTitulo="Warning"
        mensaje="¿Seguro desea cambiar compañía?"
        textoBtn1="Continuar y Cambiar Compañía"
        textoBtn2="Cancelar"
        accionDialogBtn1={cambiarDialogBtn1}
        accionDialogBtn2={cambiarDialogBtn2}
      />

      <Dialog
        open={confirmarAccionPerfil}
        titulo="¡Advertencia!"
        estiloTitulo="Warning"
        mensaje="¿Seguro desea cancelar el proceso?, Existen cambios sin grabar si procede serán descartados."
        textoBtn1="Continuar y Descartar Cambios"
        textoBtn2="Cancelar"
        accionDialogBtn1={perfilDialogBtn1}
        accionDialogBtn2={perfilDialogBtn2}
      />
    </>
  );
}
