import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { startEditing, stopEditing } from "../documents/documentSlice";

export const useDocument = () => {
  const dispatch = useDispatch<AppDispatch>();

  const document = useSelector((state: RootState) => state.document);

  const dispatchStartEditing = () => {
    dispatch(startEditing());
  };

  const dispatchStopEditing = () => {
    dispatch(stopEditing());
  };

  return {
    document,
    startEditing: dispatchStartEditing,
    stopEditing: dispatchStopEditing,
  };
};
