import React, { useContext } from "react";
import { authContext } from "../../../MainComponent";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "../";
import { IAGlobalTipoBienServicio } from "../../../../AppInterfaces";

type IDocumento = {
  tipoBienServicioId?: number;
  tipoBienServicioNombre?: string;
  tipoBienServicioCodigo606?: number;
};

export function AutocompleteTipoBienServicio({
  documento,
  setDocumento,
  filtro,
  label = "Tipo de Servicio",
  required = false,
  disabled = false,
  error,
  modificando = true,
}: {
  documento: IDocumento;
  setDocumento: (documento: IDocumento) => void;
  filtro?: (doc: IAGlobalTipoBienServicio) => boolean;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  modificando?: boolean;
}) {
  const { tipoBienServicio } = useContext(authContext);

  return modificando ? (
    <Autocomplete
      options={
        tipoBienServicio.filter(filtro ? filtro : () => true).map((reg) => ({
          id: reg.id,
          nombre: reg.nombre,
          codigo606: reg.codigo606,
        })) ?? [
          {
            id: 0,
            nombre: "",
            codigo606: 0,
          },
        ]
      }
      getOptionLabel={(option) => (option.nombre ? option.nombre : "")}
      getOptionSelected={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          label={label}
          name="tipoBienServicioNombre"
          required={required}
          error={error}
        />
      )}
      onChange={(e, value) => {
        const nuevoEstado = { ...documento };

        nuevoEstado.tipoBienServicioId = value?.id ?? 0;
        nuevoEstado.tipoBienServicioNombre = value?.nombre ?? "";
        nuevoEstado.tipoBienServicioCodigo606 = value?.codigo606 ?? 0;

        setDocumento(nuevoEstado);
      }}
      value={{
        id: documento.tipoBienServicioId ?? 0,
        nombre: documento.tipoBienServicioNombre ?? "",
        codigo606: documento.tipoBienServicioCodigo606 ?? 0,
      }}
      disabled={disabled}
    />
  ) : (
    <div>
      <div>
        <strong>{label}</strong>
      </div>
      <div>
        {(documento.tipoBienServicioCodigo606 || 0) +
          " - " +
          (documento.tipoBienServicioNombre || "")}
      </div>
    </div>
  );
}
