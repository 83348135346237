import React, { useContext } from "react";

import { Box, Button, Fab, Grid, Icon, Typography } from "@mui/material";
import {
  Add,
  Check,
  ChevronLeft,
  DeleteForever,
  Edit,
  FileCopy,
  Lock,
  LockOpen,
  Print,
  Search,
  Sync,
} from "@mui/icons-material";

import { IAuthContext } from "../../../AppInterfaces";
import { authContext } from "../../MainComponent";
import { SpinnerLoader } from "../loader/loader";

export default function BarraHerramienta({
  accionNuevo,
  botonNuevoDesHabilitar = false,
  botonNuevoTexto,
  botonNuevoIcono,
  accionModificar,
  botonModificarDesHabilitar = false,
  botonModificarLoading = false,
  accionEliminar,
  botonEliminarDesHabilitar = false,
  accionConsultar,
  botonConsultarDesHabilitar = false,
  accionGrabar,
  botonGrabarDesHabilitar = false,
  botonGrabarTexto,
  botonGrabarIcono,
  accionCancelar,
  botonCancelarDesHabilitar = false,
  accionImprimir,
  botonImprimirDesHabilitar = false,
  accionConvertir,
  botonConvertirDesHabilitar = false,
  botonConvertirTexto,
  accionRefrescar,
  botonRefrescarDesHabilitar = false,
  botonRefrescarTexto,
  isGrabando = false,
  crearBotones,
}: {
  accionNuevo?: () => void;
  botonNuevoDesHabilitar?: boolean;
  botonNuevoTexto?: string;
  botonNuevoIcono?: string;
  accionModificar?: () => void;
  botonModificarDesHabilitar?: boolean;
  botonModificarLoading?: boolean;
  accionEliminar?: () => void;
  botonEliminarDesHabilitar?: boolean;
  accionConsultar?: () => void;
  botonConsultarDesHabilitar?: boolean;
  accionGrabar?: () => void;
  botonGrabarDesHabilitar?: boolean;
  botonGrabarTexto?: string;
  botonGrabarIcono?: React.ReactElement;
  accionCancelar?: () => void;
  botonCancelarDesHabilitar?: boolean;
  accionImprimir?: () => void;
  botonImprimirDesHabilitar?: boolean;
  accionConvertir?: () => void;
  botonConvertirDesHabilitar?: boolean;
  botonConvertirTexto?: string;
  accionRefrescar?: () => void;
  botonRefrescarDesHabilitar?: boolean;
  botonRefrescarTexto?: string;
  isGrabando?: boolean;
  crearBotones?: {
    mostrar?: boolean;
    eventHandler?: () => void;
    texto: string;
    icono: string;
    disabled?: boolean;
    styleButton?: React.CSSProperties;
    styleIcon?: React.CSSProperties;
  }[];
}): JSX.Element {
  const { roleNombre } = useContext(authContext) as IAuthContext;

  const iconoMostrar = (icono?: string) => {
    if (icono === "LockOpen") return <LockOpen className="iconos" />;
    else if (icono === "Lock") return <Lock className="iconos" />;
    else return <Add className="iconos" fontSize="large" />;
  };

  const iconoMostrarMovil = (icono?: string) => {
    if (icono === "LockOpen") return <LockOpen fontSize="large" />;
    else if (icono === "Lock") return <Lock fontSize="large" />;
    else return <Add fontSize="large" />;
  };

  return (
    <Grid
      container
      item
      spacing={1}
      style={{ margin: "20px 0", maxWidth: "100%" }}
    >
      {accionNuevo && (
        <Grid item xs={4} sm={2}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button
              fullWidth
              id="botonNuevoDocumento"
              onClick={() => accionNuevo()}
              className="botonNuevo"
              disabled={botonNuevoDesHabilitar ? true : false}
              variant="contained"
            >
              {iconoMostrar(botonNuevoIcono)}
              <Typography>
                {botonNuevoTexto ? botonNuevoTexto : "Nuevo"}
              </Typography>
            </Button>
          </Box>

          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Fab
              id="botonNuevoDocumento"
              color="primary"
              onClick={() => accionNuevo()}
              className="botonNuevo"
              disabled={botonNuevoDesHabilitar ? true : false}
              size="medium"
              classes={{ sizeMedium: "botonFlotante" }}
            >
              {iconoMostrarMovil(botonNuevoIcono)}
            </Fab>
          </Box>
        </Grid>
      )}

      {accionModificar && roleNombre !== "operario" && (
        <Grid item xs={4} sm={2}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button
              fullWidth
              variant="contained"
              id="botonModificarDocumento"
              onClick={() => accionModificar()}
              className="botonModificar"
              disabled={botonModificarDesHabilitar || botonModificarLoading}
            >
              {botonModificarLoading && (
                <SpinnerLoader width="40px" height="40px" />
              )}

              {!botonModificarLoading && <Edit className="iconos" />}

              <Typography>Modificar</Typography>
            </Button>
          </Box>

          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Fab
              id="botonModificarDocumento"
              onClick={() => accionModificar()}
              className="botonModificar"
              disabled={botonModificarDesHabilitar || botonModificarLoading}
              size="medium"
              classes={{ sizeMedium: "botonFlotante" }}
            >
              {botonModificarLoading && (
                <SpinnerLoader width="40px" height="40px" />
              )}

              {!botonModificarLoading && <Edit fontSize="large" />}
            </Fab>
          </Box>
        </Grid>
      )}

      {accionEliminar &&
        (roleNombre === "administrador" || roleNombre === "sysadmin") && (
          <Grid item xs={4} sm={2}>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Button
                fullWidth
                variant="contained"
                id="botonEliminar"
                onClick={() => accionEliminar()}
                className="botonEliminar"
                disabled={botonEliminarDesHabilitar ? true : false}
              >
                <DeleteForever className="iconos" />
                <Typography>Eliminar</Typography>
              </Button>
            </Box>

            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <Fab
                id="botonEliminar"
                onClick={() => accionEliminar()}
                disabled={botonEliminarDesHabilitar ? true : false}
                className="botonEliminar"
                size="medium"
                classes={{ sizeMedium: "botonFlotante" }}
              >
                <DeleteForever fontSize="large" />
              </Fab>
            </Box>
          </Grid>
        )}

      {accionConsultar && (
        <Grid item xs={4} sm={2}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button
              fullWidth
              id="botonConsultar"
              onClick={() => accionConsultar()}
              variant="contained"
              className="botonConsultar"
              disabled={botonConsultarDesHabilitar ? true : false}
            >
              <Search className="iconos" />
              <Typography>Consultar</Typography>
            </Button>
          </Box>

          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Fab
              id="botonConsultar"
              color="default"
              onClick={() => accionConsultar()}
              disabled={botonConsultarDesHabilitar ? true : false}
              className="botonConsultar"
              size="medium"
              classes={{ sizeMedium: "botonFlotante" }}
            >
              <Search fontSize="large" />
            </Fab>
          </Box>
        </Grid>
      )}

      {accionGrabar && (
        <Grid item xs={4} sm={2}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              id="botonGuardar"
              onClick={() => accionGrabar()}
              className="botonGrabar"
              disabled={botonGrabarDesHabilitar || isGrabando}
            >
              {isGrabando ? (
                <SpinnerLoader width="40px" height="40px" />
              ) : botonGrabarIcono ? (
                botonGrabarIcono
              ) : (
                <Check className="iconos" fontSize="large" />
              )}

              <Typography>
                {botonGrabarTexto ? botonGrabarTexto : "Guardar"}
              </Typography>
            </Button>
          </Box>

          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Fab
              id="botonGuardar"
              onClick={() => accionGrabar()}
              className="botonGrabar"
              disabled={botonGrabarDesHabilitar}
              size="medium"
              classes={{ sizeMedium: "botonFlotante" }}
            >
              {isGrabando ? (
                <SpinnerLoader width="40px" height="40px" />
              ) : botonGrabarIcono ? (
                botonGrabarIcono
              ) : (
                <Check fontSize="large" />
              )}
            </Fab>
          </Box>
        </Grid>
      )}

      {accionCancelar && (
        <Grid item xs={4} sm={2}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="botonCancelar"
              id="botonCancelar"
              onClick={() => accionCancelar()}
              disabled={botonCancelarDesHabilitar ? true : false}
            >
              <ChevronLeft className="iconos" fontSize="large" />
              <Typography>Regresar</Typography>
            </Button>
          </Box>

          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Fab
              id="botonCancelar"
              onClick={() => accionCancelar()}
              className="botonCancelar"
              disabled={botonCancelarDesHabilitar ? true : false}
              size="medium"
              classes={{ sizeMedium: "botonFlotante" }}
            >
              <ChevronLeft fontSize="large" />
            </Fab>
          </Box>
        </Grid>
      )}

      {accionImprimir && (
        <Grid item xs={4} sm={2}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button
              fullWidth
              variant="contained"
              id="botonImprimir"
              className="botonImprimir"
              disabled={botonImprimirDesHabilitar ? true : false}
              onClick={accionImprimir}
            >
              <Print className="iconos" />
              <Typography>Imprimir</Typography>
            </Button>
          </Box>

          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Fab
              id="botonImprimir"
              color="default"
              disabled={botonImprimirDesHabilitar ? true : false}
              className="botonImprimir"
              size="medium"
              classes={{ sizeMedium: "botonFlotante" }}
              onClick={accionImprimir}
            >
              <Print fontSize="large" />
            </Fab>
          </Box>
        </Grid>
      )}

      {accionConvertir && (
        <Grid item xs={4} sm={2}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button
              fullWidth
              variant="contained"
              id="botonConvertir"
              className="botonConvertir"
              disabled={botonConvertirDesHabilitar ? true : false}
              onClick={accionConvertir}
            >
              <FileCopy className="iconos" />
              <Typography>
                {botonConvertirTexto ? botonConvertirTexto : "Imprimir"}
              </Typography>
            </Button>
          </Box>

          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Fab
              id="botonConvertir"
              color="default"
              disabled={botonConvertirDesHabilitar ? true : false}
              className="botonConvertir"
              size="medium"
              classes={{ sizeMedium: "botonFlotante" }}
              onClick={accionConvertir}
            >
              <FileCopy fontSize="large" />
            </Fab>
          </Box>
        </Grid>
      )}

      {accionRefrescar && (
        <Grid item xs={4} sm={2}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button
              fullWidth
              variant="contained"
              id="botonRefrescar"
              className="botonRefrescar"
              disabled={botonRefrescarDesHabilitar ? true : false}
              onClick={accionRefrescar}
            >
              <Sync className="iconos" />
              <Typography>
                {botonRefrescarTexto ? botonRefrescarTexto : "Refrescar"}
              </Typography>
            </Button>
          </Box>

          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Fab
              id="botonRefrescar"
              color="default"
              disabled={botonRefrescarDesHabilitar ? true : false}
              className="botonRefrescar"
              size="medium"
              classes={{ sizeMedium: "botonFlotante" }}
              onClick={accionRefrescar}
            >
              <Sync fontSize="large" />
            </Fab>
          </Box>
        </Grid>
      )}

      {crearBotones?.map((boton) =>
        boton.mostrar === true || boton.mostrar === undefined ? (
          <Grid key={boton.texto} item xs={4} sm={2}>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Button
                fullWidth
                variant="contained"
                id={`arrayBoton - ${boton.texto}`}
                className="botonRefrescar"
                disabled={boton.disabled}
                onClick={boton.eventHandler}
                style={boton.styleButton}
              >
                <Icon
                  className={boton.icono}
                  style={{ marginRight: 15, fontSize: "3rem" }}
                />
                <strong style={boton.styleButton}>
                  <Typography>
                    {boton.texto ? boton.texto : `btn - ${boton.texto}`}
                  </Typography>
                </strong>
              </Button>
            </Box>

            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <Fab
                id={`arrayBoton - ${boton.texto}`}
                color="default"
                disabled={boton.disabled}
                size="medium"
                className="botonRefrescar"
                classes={{ sizeMedium: "botonFlotante" }}
                onClick={boton.eventHandler}
                style={boton.styleButton}
              >
                <Icon
                  className={boton.icono}
                  style={{ marginRight: 0, fontSize: "3rem" }}
                />
              </Fab>
            </Box>
          </Grid>
        ) : null
      ) ?? null}
    </Grid>
  );
}
