import React, { useContext } from "react";
import { authContext } from "../../../MainComponent";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "../";
import { ITallerMecanicaVehiculo } from "../../../../AppInterfaces";
import { useQuery } from "react-query";
import { Consultar } from "../../server/funcionesServidor";
import { IconButton, Tooltip } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";

export function AutocompleteTallerMecanicaVehiculo({
  onChange,
  onKeyUp,
  onButtonClick,
  label = "Placa / VIN del Vehículo",
  disabled = false,
}: {
  onChange: (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: {
      vehiculoId: number;
      vehiculoPlaca: string;
      vehiculoMarca: string;
      vehiculoModelo: string;
      vehiculoColor: string;
      vehiculoAno: number;
      clienteRncCedula: string;
      clienteNombre: string;
    } | null
  ) => void;
  onKeyUp?: React.KeyboardEventHandler<HTMLDivElement>;
  onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  label?: string;
  disabled?: boolean;
}) {
  const {
    companiaSeleccionada: { id: companiaId },
  } = useContext(authContext);

  const { data: vehiculos } = useQuery(
    "TallerMecanicaVehiculos",
    async () =>
      await Consultar<ITallerMecanicaVehiculo>(
        `api/TallerMecanicaVehiculos/consultar`,
        undefined,
        undefined,
        { companiaId }
      )
  );

  return (
    <span style={{ display: "flex" }}>
      <Autocomplete
        options={
          vehiculos
            ? vehiculos.map((vehiculo) => ({
                vehiculoId: vehiculo.id ?? 0,
                vehiculoPlaca: vehiculo.placa,
                vehiculoMarca: vehiculo.marca,
                vehiculoModelo: vehiculo.modelo,
                vehiculoColor: vehiculo.color,
                vehiculoAno: vehiculo.ano,
                clienteRncCedula: vehiculo.clienteRncCedula,
                clienteNombre: vehiculo.clienteNombre,
              }))
            : [
                {
                  vehiculoId: 0,
                  vehiculoPlaca: "",
                  vehiculoMarca: "",
                  vehiculoModelo: "",
                  vehiculoColor: "",
                  vehiculoAno: 0,
                  clienteRncCedula: "",
                  clienteNombre: "",
                },
              ]
        }
        getOptionLabel={(option) =>
          option.vehiculoPlaca
            ? "Vehiculo: " +
              option.vehiculoPlaca +
              " - " +
              option.vehiculoMarca +
              " - " +
              option.vehiculoModelo +
              " - " +
              option.vehiculoColor +
              " - " +
              option.vehiculoAno +
              " | Cliente: " +
              option.clienteRncCedula +
              " - " +
              option.clienteNombre
            : ""
        }
        getOptionSelected={(option, value) =>
          JSON.stringify(option) === JSON.stringify(value)
        }
        renderInput={(params) => (
          <TextField
            params={{ ...params }}
            autoFocus
            name="placa"
            label={label}
          />
        )}
        onChange={onChange}
        onKeyUp={onKeyUp}
        style={{ flex: 2 }}
        disabled={disabled}
      />

      {onButtonClick && (
        <Tooltip title="Nuevo Vehículo">
          <IconButton onClick={onButtonClick} style={{ flexBasis: 48 }}>
            <AddCircleOutline />
          </IconButton>
        </Tooltip>
      )}
    </span>
  );
}
