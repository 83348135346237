import React, { Component } from "react";
import "./App.css";
import "./styles/tables.css";
import { BrowserRouter } from "react-router-dom";
import Main from "./components/MainComponent";
import LoginTecnico from "./components/ordenServicioTecnicos/LoginTecnico/LoginTecnico";
import { ToastContainer } from "react-toastify";
import { CssBaseline as CSSBaselineOld } from "@material-ui/core";
import { CssBaseline } from "@mui/material";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ToastContainer />
        <CSSBaselineOld />
        <CssBaseline />

        {window.location.pathname === "/orden-servicio-tecnicos" ? (
          <>
            <LoginTecnico rutaIcono={"logo192.png"} />
          </>
        ) : (
          <Main />
        )}
      </BrowserRouter>
    );
  }
}

export default App;
