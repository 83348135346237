import { localStorageName } from "../../MainComponent";
import MensajeAlerta from "../mensajeAlerta/mensajeAlerta";
import MensajesError from "../MensajesError/MensajesError";

const baseUrl =
  (process.env.NODE_ENV as string) === "production_ideecop"
    ? process.env.REACT_APP_API_URL_PROD_IDECOOP
    : process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

const serverCall = async <T,>(
  rutaAPI: string,
  method: string,
  headers: any = {},
  body?: any,
  messageOnSuccess?: string,
  tokenAUtilizar?: string,
  noUsarToken: boolean = false
): Promise<T[]> => {
  let autorizacion: any = {};
  //Reset pass no usa tokens
  if (!noUsarToken)
    autorizacion = {
      Authorization: tokenAUtilizar
        ? tokenAUtilizar
        : JSON.parse(localStorage.getItem(localStorageName) ?? "{}")
            .tokenActual,
    };

  // console.log(
  //   "Valores del fetch:",
  //   rutaAPI,
  //   method,
  //   headers,
  //   body,
  //   messageOnSuccess,
  //   autorizacion
  // );

  try {
    const response: any = await fetch(rutaAPI, {
      method,
      headers: {
        "Content-Type": "application/json",
        ...autorizacion,
        ...headers,
      },
      credentials: "same-origin",
      body,
    });

    let documento: any[] = [];

    try {
      documento = await response.json();
    } catch (error) {
      console.error("Error al convertir a json:", error);
    }

    //Se convierte de object a array para tener respuestas estandarizadas
    if (typeof documento === "string" || documento.length === undefined) {
      documento = [documento];
    }

    const respuesta: T[] = documento;

    // console.log('Respuesta del servidor:', respuesta);

    if (response.ok) {
      if (messageOnSuccess) MensajeAlerta("success", messageOnSuccess);

      return respuesta;
    } else throw new Error(JSON.stringify(documento[0].error.message));
  } catch (mensajeError: any) {
    if (mensajeError.message !== "Failed to fetch") {
      //Se procesa el error por el catalogo de errores que hay si no existe se devuelve el error como tal
      const error: any = MensajesError(mensajeError.message)
        ? MensajesError(mensajeError.message)
        : mensajeError.message;

      if (error !== "-") MensajeAlerta("error", error, false);
      console.error("Error del servidor:", mensajeError.message);
    }

    //Se estandariza los errores para devolver arreglo vacio de forma que se pueda asignar automatico a variables de manejo de data
    return [];
  }
};

export const Grabar = <T,>(
  rutaAPI: string,
  info: any,
  isCrearModificar: boolean = false
): Promise<T[]> =>
  serverCall(
    baseUrl +
      rutaAPI +
      (!isCrearModificar ? (info.id ? `/${info.id}` : ``) : ``),
    !isCrearModificar ? "PATCH" : "POST",
    undefined,
    JSON.stringify(info),
    "Documento actualizado satisfactoriamente."
  );

/**
 * Ejecuta el custom end point para grabar y actualizar
 */
export const GrabarCustom = <T,>(
  rutaAPI: string,
  documento: T,
  noMostrarMensaje: boolean = false
): Promise<T[]> =>
  serverCall(
    baseUrl + rutaAPI,
    "POST",
    undefined,
    JSON.stringify(documento),
    noMostrarMensaje ? undefined : "Documento actualizado satisfactoriamente."
  );

/**
 * Graba varios documentos al mismo tiempo
 */
export const GrabarVariosDocumentosNuevos = <T,>(
  rutaAPI: string,
  info: any[]
): Promise<T[]> =>
  serverCall(
    baseUrl + rutaAPI,
    "POST",
    undefined,
    JSON.stringify(info),
    "Documentos grabados satisfactoriamente."
  );

export const Consultar = <T,>(
  rutaAPI: string,
  filtro?: string,
  tokenAUtilizar?: string,
  arregloHeaders?: any
): Promise<T[]> =>
  serverCall(
    baseUrl + rutaAPI + (filtro ? `?filter=${JSON.stringify(filtro)}` : ``),
    "GET",
    arregloHeaders,
    undefined,
    undefined,
    tokenAUtilizar,
    undefined
  );

export const Ejecutar = <T,>(
  rutaAPI: string,
  arregloHeaders?: any,
  body?: any,
  message?: string,
  tokenAUtilizar?: string,
  noUsarToken?: boolean
): Promise<T[]> =>
  serverCall(
    baseUrl + rutaAPI,
    "POST",
    arregloHeaders,
    body ? JSON.stringify(body) : undefined,
    message,
    tokenAUtilizar,
    noUsarToken
  );

export const Eliminar = <T,>(
  rutaAPI: string,
  id: number | string,
  noMostrarMensaje: boolean = false
): Promise<T[]> =>
  serverCall(
    baseUrl + rutaAPI + `/${id}`,
    "DELETE",
    undefined,
    undefined,
    noMostrarMensaje ? undefined : "Documento eliminado satisfactoriamente."
  );

/**
 * Ejecuta el custom end point para eliminar
 */
export const EliminarCustom = <T,>(
  rutaAPI: string,
  documento: any,
  noMostrarMensaje: boolean = false
): Promise<T[]> =>
  serverCall(
    baseUrl + rutaAPI,
    "DELETE",
    undefined,
    JSON.stringify(documento),
    noMostrarMensaje ? undefined : "Documento eliminado satisfactoriamente."
  );
