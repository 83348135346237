import { configureStore } from "@reduxjs/toolkit";
import documentReducer from "./documents/documentSlice";
import { continuoApi } from "./continuoApi";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    [continuoApi.reducerPath]: continuoApi.reducer,
    document: documentReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
